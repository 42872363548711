import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { showAlert } from 'src/app/utils';
import { Service } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-edit-service-ropo',
    templateUrl: './edit-service-ropo.component.html',
    styleUrls: ['./edit-service-ropo.component.scss'],
})
export class EditServiceRopoComponent implements OnInit {
    services: Service[];

    constructor(
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private alertCtrl: AlertController
    ) {}

    ngOnInit() {}

    async confirm() {
        let missingRequired = false;
        for (let s of this.services) {
            if ((s.roRequired && !s.ro) || (s.poRequired && !s.po)) {
                missingRequired = true;
            }
        }
        if (missingRequired) {
            await showAlert('MISSING_REQUIRED_FIELDS', this.translate, this.alertCtrl);
        } else {
            this.dismiss(this.services);
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
