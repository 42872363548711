import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InformationService } from 'src/app/providers/information-service/information.service';

@Component({
    selector: 'app-production-report-detail',
    templateUrl: './production-report-detail.component.html',
    styleUrls: ['./production-report-detail.component.scss'],
})
export class ProductionReportDetailComponent implements OnInit {
    detail: any[] = [];
    dealerName = '';
    isPiecework = false;
    has51Permission = false;
    selectedEmployeeName = null;
    selectedOrderBy: string;
    orderByValues = [
        {
            value: 't.totals_price asc',
            name: 'Production Ascendent',
        },
        {
            value: 't.totals_price desc',
            name: 'Production Descendent',
        },
        {
            value: 't.department, t.nombre asc',
            name: 'Service Ascendent',
        },
        {
            value: 't.department, t.nombre desc',
            name: 'Service Descendent',
        },
    ];
    serviceParams: any;
    constructor(private modalCtrl: ModalController, private informationService: InformationService) {}

    ngOnInit() {}

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async changeOrder() {
        this.serviceParams.orderBy = this.selectedOrderBy;
        this.detail = await this.informationService.getProductionReportDetail(this.serviceParams);
    }
}
