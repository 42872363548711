import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig, APP_CONFIG } from '../../app.config';
import { AuthService } from '../auth-service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {
    Parameter,
    convertFromShittyParameter,
    Department,
    Dealer,
    getDepartmentFromResponse,
    Service,
    getServiceFromResponse,
    Workflow,
    convertShittyWorkflow,
    User,
    getEmployeeFromRequest,
    convertResponseDealer,
} from '../../../../../srs-common/src/models';
import { StorageService } from 'src/app/services/storage.service';
import { BaseService } from '../BaseService';
import { GlobalEvents } from 'src/app/services/events.service';
import { AlertController } from '@ionic/angular';
import * as memoizee from 'memoizee';
@Injectable({
    providedIn: 'root',
})
export class InformationService extends BaseService {
    public lang: string;
    minutesInMillis = 10 * 60 * 1000;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        private authService: AuthService,
        private translate: TranslateService,
        globalEvents: GlobalEvents,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
    }

    getInvoiceWorkflows() {
        return new Promise<Workflow[]>(async (resolve, reject) => {
            this.http
                .post(`${this.config.apiEndpoint}/php/api/api.workflow.php`, {
                    tipo: 2,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => convertShittyWorkflow(i));
                        resolve(invoices);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getProvidersByUser() {
        return new Promise<Dealer[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http.post(`${this.config.apiEndpoint}/php/api/api.dealers.php`, activeIds).subscribe(
                (resp: any) => {
                    const invoices = (resp.data as any[]).map(
                        (i) =>
                            ({
                                id: i.id,
                                name: i.razonSocial,
                                logo: i.logoImg,
                            } as Dealer)
                    );
                    resolve(invoices);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getDepartments() {
        return new Promise<Department[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http.post(`${this.config.apiEndpoint}/php/api/department.php`, activeIds).subscribe(
                (resp: any) => {
                    const invoices = (resp.data as any[]).map((i) => getDepartmentFromResponse(i));
                    resolve(invoices);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getServices(id: number, idInvoice?: any, idCar?: number) {
        return new Promise<Service[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http
                .post(`${this.config.apiEndpoint}/php/api/invoiceService.php`, {
                    ...activeIds,
                    idDepartment: id,
                    showScanner: 1,
                    idInvoice,
                    idCar,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => getServiceFromResponse(i));
                        resolve(invoices.sort((a, b) => a.department.id - b.department.id));
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getAllServices(idDealerIn?: string, idCar?: number) {
        return new Promise<Service[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http
                .post(`${this.config.apiEndpoint}/php/api/invoiceService.php`, {
                    idUsuario: activeIds.idUsuario,
                    idDealer: !idDealerIn ? activeIds.idDealer : null,
                    idDealerProvider: !idDealerIn ? activeIds.idDealerProvider : null,
                    showScanner: 1,
                    idDealerIn,
                    idCar,
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => getServiceFromResponse(i));
                        resolve(invoices.sort((a, b) => a.department.id - b.department.id));
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    getEmployees = memoizee(this.mGetEmployees, { maxAge: 5000, promise: true });

    mGetEmployees(showInExpenses?: boolean) {
        return new Promise<User[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            const params = {
                ...activeIds,
                filterEmployee: 1,
            };
            if (showInExpenses) {
                params['showInExpenses'] = 1;
            } else {
                params['showScanner'] = 1;
            }
            this.http.post(`${this.config.apiEndpoint}/php/api/api.employees.php?hide_loading`, params).subscribe(
                (resp: any) => {
                    const invoices = (resp.data as any[]).map((i) => getEmployeeFromRequest(i));
                    resolve(invoices);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getAdvisors = memoizee(this.mGetAdvisors, { maxAge: 5000, promise: true });

    mGetAdvisors() {
        return new Promise<User[]>(async (resolve, reject) => {
            const activeIds = await this.authService.getActiveIds();
            this.http
                .post(`${this.config.apiEndpoint}/php/api/api.employees.php?hide_loading`, {
                    ...activeIds,
                    filterEmployee: 1,
                    showScanner: 1,
                    nameRoleLike: 'advisor',
                })
                .subscribe(
                    (resp: any) => {
                        const invoices = (resp.data as any[]).map((i) => getEmployeeFromRequest(i));
                        resolve(invoices);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async getColors() {
        const activeIds = await this.authService.getActiveIds();
        const lastColorFetchId: number = await this.storage.get('lastColorFetchId');
        const lastFetchTime: number = await this.storage.get('lastColorFetchTime', 0);
        const lastColors = await this.storage.get('colors');
        return new Promise<Parameter[]>(async (resolve, reject) => {
            if (
                lastColorFetchId !== activeIds.idDealer ||
                !lastColors ||
                !lastFetchTime ||
                Date.now() - lastFetchTime > this.minutesInMillis
            ) {
                this.http
                    .post(`${this.config.apiEndpoint}/php/api/colors.php?hide_loading`, {
                        ...activeIds,
                    })
                    .subscribe(
                        async (resp: any) => {
                            const colors = (resp.data as any[]).map((i) => convertFromShittyParameter(i));
                            await this.storage.set('lastColorFetchId', activeIds.idDealer);
                            await this.storage.set('lastColorFetchTime', Date.now());
                            await this.storage.set('colors', colors);
                            resolve(colors);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            } else {
                resolve(lastColors);
            }
        });
    }

    async setColors() {
        const colors = await this.getColors();
        await this.storage.set('colors', colors);
    }

    getModel(id: number) {
        return new Promise<Parameter[]>((resolve, reject) => {
            this.http
                .get(`${this.config.apiEndpoint}/php/api/makemodel.php?maker_id=${id}&models=1`)
                .subscribe((resp: any) => {
                    const modelData = resp.data.map((i) => convertFromShittyParameter(i));
                    resolve(modelData);
                });
        });
    }

    getMakers() {
        return new Promise<Parameter[]>((resolve, reject) => {
            this.http.get(`${this.config.apiEndpoint}/php/api/makemodel.php?hide_loading`).subscribe((resp: any) => {
                const makerData = resp.data.map((i) => convertFromShittyParameter(i));
                resolve(makerData);
            });
        });
    }

    async getLang() {
        if (!this.lang) {
            this.lang = await this.storage.get('lang', 'en');
        }
        return this.lang;
    }

    changeLang(lang: string) {
        this.translate.use(lang);
        this.storage.set('lang', lang);
        this.lang = lang;
    }

    async getDistricts(): Promise<Parameter[]> {
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.districts.php`, {
            tipo: 'get_districts',
        });
        return resp.data.map((d) => {
            return convertFromShittyParameter(d);
        });
    }

    async getDealersByDistrict(idDistrict: number[]): Promise<Dealer[]> {
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.districts.php`, {
            tipo: 'get_dealers_by_district',
            idDistrict: idDistrict.join(','),
        });
        return resp.data.map((sd) => {
            return convertResponseDealer({ id: sd.id, razonSocial: sd.nombre } as any);
        });
    }

    async getProductionReport(args: {
        idsDealer: number[];
        woIsBilled: boolean;
        dateFrom: string;
        dateTo: string;
        dateDONE: boolean;
        orderBy: string;
        idEmployee?: number;
        idDepartment?: number;
        idServicesIn?: string;
        todayProduction?: number;
        serviceNames?: string;
    }) {
        const {
            idsDealer,
            woIsBilled,
            dateFrom,
            dateTo,
            dateDONE,
            orderBy,
            idEmployee,
            idDepartment,
            idServicesIn,
            todayProduction,
            serviceNames,
        } = args;
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/reports/api.productionreport.php`, {
            tipo: 'report-dealer',
            idsDealer: idsDealer.join(','),
            woIsBilled,
            dateFrom,
            dateTo,
            dateDONE,
            orderBy,
            idEmployee,
            idDepartment,
            idServicesIn,
            todayProduction,
            serviceNames,
        });
        return data.map((i) => ({
            idDealer: +i.idDealer,
            idDealerProvider: +i.idDealerProvider,
            providerName: i.razonSocialProvider,
            name: i.razonSocial,
            piecework: Number(i.piecework),
            totals: Number(i.totals),
            hasData: i.haveData === 1 ? true : false,
        }));
    }

    async getProductionReportDetail(args: {
        idDealer: number;
        woIsBilled: boolean;
        dateFrom: string;
        dateTo: string;
        dateDONE: boolean;
        isPiecework?: boolean;
        idEmployee?: number;
        idDepartment?: number;
        idServicesIn?: string;
        todayProduction?: number;
        serviceNames?: string;
        orderBy?: string;
    }) {
        const {
            idDealer,
            woIsBilled,
            dateFrom,
            dateTo,
            dateDONE,
            isPiecework,
            idEmployee,
            idDepartment,
            idServicesIn,
            todayProduction,
            serviceNames,
            orderBy,
        } = args;
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/reports/api.productionreport.php`, {
            tipo: isPiecework ? 'get-details-piecework' : 'get-details',
            idDealer,
            woIsBilled,
            dateFrom,
            dateTo,
            dateDONE,
            idEmployee,
            idDepartment,
            idServicesIn,
            todayProduction,
            serviceNames,
            orderBy,
        });
        return data.map((i) => ({
            idDealer: +i.idDealer,
            idDealerProvider: +i.idDealerProvider,
            providerName: i.provider,
            totals: Number(i.totalsPrice),
            piecework: Number(i.pieceworkOfService),
            department: i.department,
            name: i.nombre,
            quantity: +i.quantity,
            service: i.service,
            fullNro: i.fullNro,
            ro: i.ro,
            po: i.po,
            employee: i.employee,
            car: {
                color: i.color,
                vinNumber: i.vin,
                stockNumber: i.stockNumber,
                year: i.year,
                make: i.makeNombre,
                model: i.modelNombre,
            },
        }));
    }

    async getGpsConfig() {
        const resp = await this.apiGet(
            `${this.config.nodeEndpoint}/gps_module/${this.authService.user.id}/get_config?app=${this.config.appName}`
        );
        const data = resp;
        data.path = this.config.nodeEndpoint + data.path;
        return data as { token: string; path: string };
    }
}
