import { Component, Inject, OnInit } from '@angular/core';
import { AlertController, IonRouterOutlet, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { ImageViewerComponent } from 'src/app/pages/image-viewer/image-viewer.component';
import { InvoiceService } from 'src/app/providers/invoice-service/invoice.service';
import { InspectionHistory } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-inspection-history',
    templateUrl: './inspection-history.component.html',
    styleUrls: ['./inspection-history.component.scss'],
})
export class InspectionHistoryComponent implements OnInit {
    woId: number;
    history: InspectionHistory[] = [];
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private navCtrl: NavController,
        private alertCtrl: AlertController,
        private invoiceService: InvoiceService
    ) {}

    async ngOnInit() {
        this.history = await this.invoiceService.getInvoiceInspectionHistory(this.woId);
    }

    async viewImage(history: InspectionHistory) {
        if (!history.signaturePath) return;
        const modal = await this.modalCtrl.create({
            component: ImageViewerComponent,
            componentProps: { imgSource: [{ url: this.config.apiEndpoint + history.signaturePath }], pos: 0 },
            swipeToClose: false,
        });
        await modal.present();
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
