import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-print-types-popover',
    templateUrl: './print-types-popover.component.html',
    styleUrls: ['./print-types-popover.component.scss'],
})
export class PrintTypesPopoverComponent implements OnInit {
    types: { text: string; type: string }[] = [];
    constructor(private popoverController: PopoverController) {}

    ngOnInit() {}

    returnType(printType) {
        this.dismissPopover(printType.type);
    }

    dismissPopover(data?: any) {
        this.popoverController.dismiss(data);
    }
}
