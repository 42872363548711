import { NgZone } from '@angular/core';
import { BleClient, BleDevice, dataViewToText, numberToUUID, textToDataView } from '@capacitor-community/bluetooth-le';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GlobalEvents } from './services/events.service';
import { confirmAction } from './utils';
export class GpsModule {
    notification_type: string;
    current_value: string;
    stream_started = false;
    device: BleDevice;
    subscription: Subscription;
    totalQueue: number;
    commands = {
        ssid: { check: 'check+ssid', set: 'sws' },
        pass: { check: 'check+pass', set: 'swp' },
        url: { check: 'check+url', set: 'sur' },
        dealer: { check: 'check+dealer', set: 'sdl' },
        provider: { check: 'check+provider', set: 'spr' },
        car: { check: 'check+car', set: 'scr' },
        token: { check: 'check+token', set: 'stk' },
        active: { check: 'check+active', set: 'sac' },
        gps: { check: 'check+gps', set: null },
        wifi: { check: 'check+wifi', set: null },
        version: { check: 'check+version', set: null },
    };
    values = {
        ssid: null,
        pass: null,
        url: null,
        dealer: null,
        provider: null,
        car: null,
        token: null,
        active: null,
        gps: null,
        wifi: null,
        version: null,
    };
    queue: any[] = [];
    isConnected = false;
    resendTimeout;
    constructor(
        private events: GlobalEvents,
        private zone: NgZone,
        private translate: TranslateService,
        private alertCtrl: AlertController
    ) {
        this.subscription = this.events.getObservable().subscribe(async (e) => {
            this.zone.run(async () => {
                if (e.event === 'NOTIFICATION') {
                    const data = dataViewToText(e.data).trim();
                    console.log(data);
                    if (data !== 'INVALID') {
                        clearInterval(this.resendTimeout);
                    }
                    if (
                        (!this.stream_started && data === 'OK' && !this.isQueueEmpty()) ||
                        (data === 'OK' && this.isQueueEmpty()) ||
                        data === 'OK'
                    ) {
                        this.events.publish({
                            data: Math.round((this.queue.length / this.totalQueue) * 100),
                            event: 'REPORT_PROGRESS',
                        });
                        this.continueQueue();
                    } else {
                        this.parseNotification(data);
                    }
                }
            });
        });
        this.init();
    }

    async continueQueue() {
        if (this.queue.length) {
            const a = this.processQueue();
            console.log('command sent', dataViewToText(a));
            BleClient.writeWithoutResponse(this.device.deviceId, numberToUUID(0xffe0), numberToUUID(0xffe1), a);
            this.resendTimeout = setInterval(() => {
                BleClient.writeWithoutResponse(this.device.deviceId, numberToUUID(0xffe0), numberToUUID(0xffe1), a);
            }, 7000);
        } else {
            if (!this.stream_started) {
                this.totalQueue = 0;
                this.events.publish({ data: null, event: 'GPS_QUEUE_FINISHED' });
                console.log(this.values);
            }
        }
    }

    async init() {
        try {
            await BleClient.initialize();

            this.device = await BleClient.requestDevice({
                namePrefix: 'SRS-GPS',
                optionalServices: [numberToUUID(0xffe0), numberToUUID(0xffe1)],
            });
            await BleClient.connect(this.device.deviceId);
            this.events.publish({ data: this.device, event: 'GPS_DEVICE_CONNECTED' });

            await BleClient.startNotifications(
                this.device.deviceId,
                numberToUUID(0xffe0),
                numberToUUID(0xffe1),
                (value) => {
                    this.events.publish({ event: 'NOTIFICATION', data: value });
                }
            );
        } catch (error) {
            console.error(error);
        }
    }

    async disconnect(): Promise<Boolean> {
        return new Promise((resolve) => {
            confirmAction(
                {
                    action: 'Disconnect?',
                    callback: async () => {
                        if (this.device) {
                            await BleClient.disconnect(this.device.deviceId);
                            this.device = null;
                        }
                        if (!!this.subscription) {
                            this.subscription.unsubscribe();
                        }
                        resolve(true);
                    },
                    cancelCallback: () => {
                        resolve(false);
                    },
                },
                this.translate,
                this.alertCtrl
            );
        });
    }

    pushToQueue(element: any) {
        this.queue.push(element);
    }

    processQueue() {
        return this.queue.shift();
    }

    isQueueEmpty() {
        return this.queue.length === 0;
    }

    parseNotification(notification: string) {
        if (notification.endsWith('_START')) {
            this.notification_type = notification.split('_')[0];
            this.stream_started = true;
            return;
        } else if (notification.endsWith('_END')) {
            this.stream_started = false;
            const result = { value: this.current_value, type: this.notification_type };
            this.values[this.notification_type.toLowerCase()] = this.current_value;
            this.current_value = '';
            this.events.publish({
                data: Math.round((this.queue.length / this.totalQueue) * 100),
                event: 'REPORT_PROGRESS',
            });
            this.continueQueue();
            return result;
        } else {
            if (!this.current_value) {
                this.current_value = '';
            }
            this.current_value = this.current_value + (notification.includes('ÿ') ? '' : notification);
            return;
        }
    }

    getModuleInfo() {
        this.queue = [];
        let commands = Object.keys(this.commands);
        this.totalQueue = 0;
        for (let i = 0; i < commands.length; i++) {
            const c = commands[i];
            this.pushToQueue(textToDataView(this.commands[c].check));
            this.totalQueue++;
        }
        this.continueQueue();
    }

    getWifiStatus() {
        this.totalQueue = 1;
        this.pushToQueue(textToDataView(this.commands.wifi.check));
        this.continueQueue();
    }

    async updateValues(values) {
        this.totalQueue = 0;
        this.pushWithValueCheck('provider', values);
        this.pushWithValueCheck('dealer', values);
        this.pushWithValueCheck('car', values);
        this.pushWithValueCheck('active', values);
        if (this.values.url !== values.url) {
            this.setUrl(values.url);
            this.values.url = values.url;
        }
        if (this.values.token !== values.token) {
            this.setToken(values.token);
            this.values.token = values.token;
        }
        this.pushWithValueCheck('pass', values);
        this.pushWithValueCheck('ssid', values);
        this.continueQueue();
    }

    pushWithValueCheck(cmd: string, values: any) {
        if (this.values[cmd] !== values[cmd]) {
            this.values[cmd] = values[cmd];
            this.pushToQueue(textToDataView(this.commands[cmd].set + values[cmd]));
            this.totalQueue = this.totalQueue + 1;
        }
    }

    async setUrl(url: string) {
        if (url) {
            const chunks = url.trim().slice(0, 56).match(new RegExp('.{1,14}', 'g'));
            for (let i = 0; i < chunks.length; i++) {
                const element = chunks[i];
                this.pushToQueue(textToDataView(this.commands.url.set + i + element));
                this.totalQueue = this.totalQueue + 1;
            }
        }
    }

    async setToken(token: string) {
        if (token) {
            const chunks = token.trim().slice(0, 36).match(new RegExp('.{1,12}', 'g'));
            for (let i = 0; i < chunks.length; i++) {
                const element = chunks[i];
                this.pushToQueue(textToDataView(this.commands.token.set + i + element));
                this.totalQueue = this.totalQueue + 1;
            }
        }
    }
}
