import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PhonePipe } from 'src/app/pipes/phone.pipe';
import { InvoiceService } from 'src/app/providers/invoice-service/invoice.service';
import { showAlert } from 'src/app/utils';
import { Phone, User } from '../../../../../srs-common/src/models';
import { SearchModal } from '../search.helper';

@Component({
    selector: 'app-phone-modal',
    templateUrl: './phone-modal.component.html',
    styleUrls: ['./phone-modal.component.scss'],
})
export class PhoneModalComponent implements OnInit {
    selected: Phone;
    name: string;
    phone: string;
    email: string;
    phoneEnabled: boolean;
    emailEnabled: boolean;
    phones: Phone[];
    searchModal: SearchModal;
    phonePipeTransform;
    initSelected: number;
    selectedId: string;
    typeContact: number;
    isRequired: boolean;
    title: string;
    constructor(
        private modalCtrl: ModalController,
        private invoiceService: InvoiceService,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {
        this.phonePipeTransform = PhonePipe.applyTransform;
    }

    async ngOnInit() {
        this.phones = await this.invoiceService.getPhones('', this.typeContact);
        if (this.initSelected) {
            this.selected = this.phones.find((o) => +o.id === +this.initSelected);
            this.selectedId = this.initSelected + '';
        }
    }

    async phoneSearch() {
        const phone = await this.searchModal.selectParameter({
            sourceList: this.phones.map((p) => ({
                id: +p.id,
                value: p.nombre,
                extra: `${p.phoneMask}${p.email ? ' - ' + p.email : ''}`,
                title: '',
            })),
            initSelected: this.selected ? +this.selected.id : null,
            enableCreation: true,
            isNumeric: false,
        });
        if (phone) {
            const selected = phone.id
                ? this.phones.find((p) => +p.id === phone.id)
                : ({
                      id: null,
                      nombre: phone.value,
                      email: '',
                      phoneMask: '',
                      estado: null,
                      phone: '',
                  } as Phone);
            this.selected = selected;
            this.name = phone.value;
            this.phone = selected ? selected.phoneMask : '';
            this.email = selected ? selected.email : '';
        }
    }

    applyPhoneMask() {
        if (this.selected.phoneMask.length === 14 && this.phone.length < 14) {
            this.phone = '';
        }
        if (this.phone.length === 10 && !this.phone.includes('(')) {
            this.selected.phone = this.phone;
            this.phone = this.phonePipeTransform(this.phone);
        }
        this.selected.phoneMask = this.phone;
    }
    selectObj() {
        if (this.selected) {
            this.dismiss({ phone: this.selected });
        }
        if (!this.selected && this.isRequired) {
            showAlert('PHONE_REQUIRED', this.translate, this.alertCtrl);
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
