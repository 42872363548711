import { Component, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';

interface SelectorObj {
    id: number;
    value: string;
    title?: string;
    isChecked?: boolean;
    pipeValue?: string;
    extra?: string;
}

@Component({
    selector: 'app-filter-selector',
    templateUrl: './filter-selector.component.html',
    styleUrls: ['./filter-selector.component.scss'],
})
export class FilterSelectorComponent {
    sourceList: any[];
    list: SelectorObj[];
    filtered: SelectorObj[];
    initSelected: number;
    selected: SelectorObj;
    multipleSelected: SelectorObj[];
    title: string;
    filterField: string;
    multiple = false;
    valueField: string;
    titleField: string;
    maxlength: number;
    enableCreation: boolean;
    searchQuery: string;
    transformValue: (value: string) => string;
    asyncSearch: (query: string) => Promise<SelectorObj[]>;
    itemsMap: { [value: string]: SelectorObj } = {};
    isNumeric = false;
    selectedId: string;
    emptyListMessage: string;
    constructor(private modalCtrl: ModalController, private zone: NgZone) {}

    ionViewDidEnter() {
        this.list = this.sourceList.map((s: any) => ({
            id: s.id,
            value: s[this.valueField ? this.valueField : this.filterField],
            title: this.getTitleValue(s, this.titleField),
            extra: s.extra,
        }));
        if (this.initSelected) {
            this.selected = this.sourceList.find((o) => +o.id === +this.initSelected);
            this.selectedId = this.enableCreation ? this.selected.value : this.initSelected + '';
        }
        this.list.forEach((o) => {
            this.itemsMap[o.value] = o;
            if (this.transformValue) {
                o.pipeValue = this.transformValue(o.value);
            }
        });
        if (this.multipleSelected && this.multipleSelected.length) {
            this.multipleSelected.forEach((s) => {
                for (const i in this.list) {
                    if (Number(s.id) === Number(this.list[i].id)) {
                        this.list[i].isChecked = true;
                        break;
                    }
                }
            });
        }
        this.resetList();
    }

    getTitleValue(obj: any, titleKey: string) {
        if (titleKey) {
            if (titleKey.includes('.')) {
                var a = titleKey.split('.');
                for (var i = 0, n = a.length; i < n; ++i) {
                    var k = a[i];
                    if (k in obj) {
                        obj = obj[k];
                    } else {
                        return;
                    }
                }
                return obj;
            } else {
                return obj[titleKey];
            }
        } else {
            return null;
        }
    }

    resetList() {
        this.filtered = this.list;
    }

    async filterList(event) {
        let query: string = event.detail.value.trim();
        if (query === '') {
            this.resetList();
        } else {
            if (this.maxlength) {
                query = query.slice(0, this.maxlength);
                this.zone.run(async () => {
                    this.searchQuery = query;
                });
            }
            if (!!this.asyncSearch) {
                this.filtered = await this.asyncSearch(query);
            } else {
                this.filtered = this.list.filter((e) => e.value.toLowerCase().includes(query.toLowerCase()));
                if (this.enableCreation && !this.filtered.length) {
                    this.filtered = [{ value: query } as any].concat(this.filtered);
                }
            }
        }
    }

    radioGroupChange(event) {
        if (this.enableCreation) {
            if (this.itemsMap[event.detail.value]) {
                this.selected = this.itemsMap[event.detail.value];
            } else {
                this.selected = {
                    id: null,
                    value: event.detail.value,
                };
            }
        } else {
            this.selected = this.filtered.find((e) => +e.id === +event.detail.value);
        }
    }

    checkboxChange(event, id) {
        for (const obj in this.list) {
            if (this.list[obj].id === id) {
                this.list[obj].isChecked = event.detail.checked;
                break;
            }
        }
    }

    selectObj() {
        if (!this.multiple) {
            if (this.selected) {
                if (!!this.asyncSearch) {
                    this.dismiss({ found: this.selected });
                } else {
                    if (this.enableCreation) {
                        this.dismiss({ found: this.selected });
                    } else {
                        const found = this.sourceList.find((s: any) => s.id === this.selected.id);
                        const index = this.sourceList.indexOf(found);
                        this.dismiss({ found, index });
                    }
                }
            } else {
                this.dismiss({ found: null, index: -1 });
            }
        } else {
            this.dismiss(this.list.filter((o) => o.isChecked));
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
