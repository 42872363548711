import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { GlobalEvents } from 'src/app/services/events.service';
import { Notification } from '../../../../../srs-common/src/models';
import { AuthService } from '../auth-service/auth.service';
import { BaseService } from '../BaseService';

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends BaseService {
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private authService: AuthService,
        globalEvents: GlobalEvents,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
    }

    async getUnreadNotificationsCount(): Promise<number> {
        const { idUsuario } = await this.authService.getActiveIds();
        const resp = await this.apiGet(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/unread_count?app=${this.config.appName}&hide_loading`
        );
        return resp;
    }

    async getNotifications(): Promise<Notification[]> {
        const { idUsuario } = await this.authService.getActiveIds();
        const resp = await this.apiGet(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/all?app=${this.config.appName}&hide_loading`
        );
        return resp;
    }

    async getType4Notifications(): Promise<Notification[]> {
        const { idUsuario } = await this.authService.getActiveIds();
        const resp = await this.apiGet(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/4/all?app=${this.config.appName}&hide_loading`
        );
        return resp;
    }

    async markNotificationsRead(idNotification: number): Promise<void> {
        const { idUsuario } = await this.authService.getActiveIds();
        return await this.apiPut(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/${idNotification}/read?app=${this.config.appName}&hide_loading`,
            {}
        );
    }

    async markNotificationsReadAll(): Promise<void> {
        const { idUsuario } = await this.authService.getActiveIds();
        return await this.apiPut(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/read_all?app=${this.config.appName}&hide_loading`,
            {}
        );
    }

    async deleteNotification(idNotification: number): Promise<void> {
        const { idUsuario } = await this.authService.getActiveIds();
        return await this.apiDelete(
            `${this.config.nodeEndpoint}/notifications/${idUsuario}/${idNotification}/delete?app=${this.config.appName}`,
            {}
        );
    }
}
