import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements AfterViewInit {
    signaturePad: SignaturePad;
    @ViewChild('canvas') canvasEl: ElementRef;
    signatureImg: string;
    constructor(private modalCtrl: ModalController) {}

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
        this.canvasEl.nativeElement.width = window.innerWidth - 35;
        this.signaturePad.clear();
    }

    startDrawing(event: Event) {
        console.log(event);
        // works in device not in browser
    }

    moved(event: Event) {
        // works in device not in browser
    }

    clearPad() {
        this.signaturePad.clear();
    }

    savePad() {
        const base64Data = this.signaturePad.toDataURL();
        this.dismiss({ signature: base64Data });
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
