export interface Email {
    id: number;
    sent: Date;
    emails: string[];
    files: any[];
    subject: string;
    description: string;
}

export interface Phone {
    id: string;
    dealer: any;
    email: string;
    estado: string;
    nombre: string;
    phone: string;
    phoneMask: string;
}

export interface Notification {
    id: number;
    idAuthor: number;
    idUser: number;
    idWorkorder: number;
    idTimeOff: number;
    titleEs: string;
    titleEn: string;
    messageEs: string;
    messageEn: string;
    isRead: boolean;
    isDeleted: boolean;
    emailSent: boolean;
    pushSent: boolean;
    date: Date;
    dateRead: Date;
    dateDeleted: Date;
    type: number;
    app: string;
    extra: string;
}
export interface Parameter {
    id?: number;
    value: string;
    title?: string;
    extra?: string;
}
export interface ShittyParameter {
    id?: number;
    idDato?: number;
    nombre: string;
}

export interface User {
    id: number;
    internalCode: number;
    name: string;
    email: string;
    logo: string;
    roles: Role[];
    dealers?: Dealer[];
    isDetailer: boolean;
    token: string;
    provider: any;
    isChecked?: boolean;
    ownerLogo: string;
    permissions?: number[];
    isUserMultiDealer: boolean;
    providerMap: {[id: number]: Dealer[]};
    phone: string;
    phoneMask: string;
    isFuelModeEnabled: boolean;
}

export interface ShittyUser {
    id: number;
    codigoInterno: number;
    nombre: string;
    email: string;
    password: string;
    celular: string;
    rol: any[];
    roles: ShittyRole[];
    rolesRel: any[];
    contratistas: ShittyDealer[];
    empresa: any;
    fechaCreacion: string;
    fechaUpdate: string;
    logoImg: string;
    estado: number;
    isDetailer: boolean;
    isUserMultiDealer: boolean;
    token: string;
    contratistaOwner: { logoImg: string };
    phone: string;
    phoneMask: string;
    hasFuelCompany: string;
}

export interface InspectionHistory {
    user: User;
    date: Date;
    type: number;
    note: string;
    lat: number;
    lng: number;
    signaturePath: string;
    signatureFile: string;
    status: number;
}

export interface ShittyRole {
    id: number;
    nombre: string;
    dealer: ShittyDealer;
    permisos: any[];
}

export interface Role {
    id: number;
    name: string;
    dealer: Dealer;
    permissions: number[];
}

export interface ShittyDealer {
    id: number;
    logoImg: string;
    razonSocial: string;
    lat: string;
    lng: string;
    radio: string;
    permisos: any[];
    isImgRequired?: string;
    hasStockRequired?: string;
    hasPoRequired?: string;
    hasRoRequired?: string;
    hasTagRequired?: string;
    hasSalesRequired?: string;
    hasGPSConfigEnabled?: string;
    ktaActions?: { actionName: string; id: string }[];
    haskeyTracker?: string;
    isMilesVisible?: string;
    isPhotoVisible?: string;
    isPoVisible?: string;
    isRoVisible?: string;
    isSalespersonVisible?: string;
    isStockVisible?: string;
    isTagVisible?: string;
    hasSalesmanRequired: number;
    hasSalespersonEmailReq?: number;
    hasSalespersonPhoneEmail: string;
    hasSecuencialWorkflow?: string;
    hasSalespersonPhoneReq: number;
    hasSalespersonWithPhone: number;
    hasNoteRequiredInternalUser: number;
    hasNoteUnapprove: number;
    hasFinalInspect: number;
    hasInternalInspect: number;
    isStatusVisible: number;
    hasQuickWo: number;
    serviceGrouping: { serviceDestination: any, servicesAgruped: any[]}[];
}

export interface ShittyDealerAsigned {
    id: number;
    logoImg: string;
    razonSocial: string;
}

export interface Dealer {
    id: number;
    logo: string;
    name: string;
    active?: boolean;
    woImageEnabled?: boolean;
    stockNumberRequired?: boolean;
    hasSecuentialWorkflow?: boolean;
    poRequired?: boolean;
    roRequired?: boolean;
    tagRequired?: boolean;
    salesRequired: boolean;
    permissions: number[];
    ktaActions: { [id: string]: { label: string } };
    keyTrackerEnabled: boolean;
    visibleFields: {
        isMilesVisible: boolean;
        isPhotoVisible: boolean;
        isPoVisible: boolean;
        isRoVisible: boolean;
        isSalespersonVisible: boolean;
        isStockVisible: boolean;
        isTagVisible: boolean;
    };
    hasSalesmanRequired: boolean;
    hasSalespersonEmailReq: boolean;
    hasSalespersonPhoneEmail: boolean;
    hasSalespersonPhoneReq: boolean;
    hasSalespersonWithPhone: boolean;
    hasNoteRequiredInternalUser: boolean;
    hasNoteUnapprove: boolean;
    hasFinalInspect: boolean;
    hasInternalInspect: boolean;
    isStatusVisible: boolean;
    hasQuickWo: boolean;
    hasGPSConfigEnabled: boolean;
    serviceGroups: {[id: string]: number[]};
}
export interface ResponseCar {
    vin: string;
    year: string;
    make: ShittyParameter;
    model: ShittyParameter;
    dealer: Dealer;
    color: string;
    tipo: string;
    stockNumber: string;
    miles: number;
    gasoline: string;
    isLoaned: boolean;
    loanActive: boolean;
    damages: any[];
    id: number;
    estado: number;
    loanWorkflow: number;
    customer: any;
    isTouch: boolean;
    lat: number;
    lng: number;
    lastLocationDate: string;
    authorLastLocation: ShittyUser;
    carPhotoMain: string;
    statusKey: string;
    qrService?: any;
}

export interface RequestCar {
    customer: any;
    dealer: Dealer;
    make: ShittyParameter;
    model: ShittyParameter;
    tipo: string;
    vin: string;
    year: string;
    color: string;
    stockNumber: string;
    miles: number;
    gasoline: string;
    id: number;
    estado: number;
}

export interface Car {
    id: number;
    vinNumber?: string;
    shortVin?: string;
    stockNumber?: string;
    year: string;
    maker: Parameter;
    model: Parameter;
    color: string;
    responseCar?: ResponseCar;
    type: string;
    isTouch: boolean;
    lat: number;
    lng: number;
    lastLocationDate: Date;
    authorLastLocation: User;
    photo: string;
    qrService?: QRCodeCarService;
    hasKey?: boolean;
}
export interface Invoice {
    id: number;
    idInvoice: number;
    ro: any;
    po: any;
    tag: any;
    miles: string;
    entryDate: Date;
    promisedDate: Date;
    promisedDateColor: string;
    observation: string;
    priority: number;
    status: number;
    workOrder: number;
    fullWo: string;
    workflow: Workflow;
    department: Department;
    dealerProvider: Dealer;
    invoiceServices: Service[];
    services: { id: number; name: string; note: string }[];
    servicesString: string;
    fuelString: string;
    salesman: string;
    advisor: any;
    technician: any;
    createdBy: string;
    approvedBy: string;
    damage: any;
    provider: any;
    employees: any[];
    employeeIds: number[];
    accessEdit: boolean;
    isApproved: null | '0' | '1';
    allowApprove: '0' | '1' | '2' | '3';
    isInspected: null | '0' | '1' | '-1';
    isInternalInspected: null | '0' | '1' | '-1';
    car?: Car;
    stockNumber?: string;
    images?: number;
    selected?: boolean;
    checkDisabled?: boolean;
    woImage?: number;
    isBilled: boolean;
    pauseNote: string;
    totalCost: number;
    phone: Phone;
    hasAddedService?: boolean;
    unapprovedReason?: string;
    spot: boolean;
}

export const showInvoiceInspectOptions = (invoice: Invoice) => {
    const isInspected = invoice.isInspected;
    const isInternalInspected = invoice.isInternalInspected;
    let isInternal,hasInternalInspect, hasFinalInspect, showApprove, showDisapprove;
        if (
            (isInternalInspected == null && isInspected === '-1') ||
            (isInternalInspected == null && isInspected === '0') ||
            (isInternalInspected == '1' && isInspected === '-1') ||
            (isInternalInspected == '1' && isInspected === '0') ||
            (isInternalInspected == '1' && isInspected === '1')
        ) {
            isInternal = false;
        } else if (
            (isInternalInspected == '-1' && (isInspected === '-1' || isInspected === null)) ||
            (isInternalInspected == '0' && isInspected === '-1') ||
            ((isInternalInspected == '0' || isInternalInspected == '1') && isInspected === null)
        ) {
            isInternal = true;
        }
        if (isInternal != undefined) {
            showApprove = isInternal
                ? isInternalInspected !== '1' && hasInternalInspect
                : isInspected !== '1' && hasFinalInspect;
            showDisapprove = isInternal
                ? isInternalInspected !== '0' && hasInternalInspect
                : isInspected !== '0' && hasFinalInspect;
        }
        return { showApprove, showDisapprove };
}

export interface Workflow {
    id: number;
    value: string;
}

export interface Department {
    id: number;
    name: string;
    dealer?: Dealer;
}

export interface Service {
    id: number;
    name: string;
    price: number;
    piecework: number;
    isChecked?: boolean;
    employeesPiecework: any;
    pos?: number;
    qty?: number;
    po?:string;
    ro?:string;
    hasQty?: boolean;
    stockNumberRequired?: boolean;
    poRequired?: boolean;
    roRequired?: boolean;
    hasPhotoRequired?: boolean;
    tagRequired?: boolean;
    salesRequired?: boolean;
    isDisabled?: boolean;
    stockSemiRequired?: boolean;
    roSemiRequired?: boolean;
    poSemiRequired?: boolean;
    noteRequired?: boolean;
    showSpotPromise?: '0'|'1'|'2'|'3';
    comment?: string;
    department: Department;
    fullName: string;
    associatedServices: number[];
    complianceTime: number;
    hasCreateWoEachService: boolean;
    automaticInvoice: boolean;
    incompatibleServices: number[];
    incompatibleServiceOf: number[];
    disabled: boolean;
    updateFields?: string;
}

export interface ResponseQRCode {
    id: number;
    qrCode: string;
    dateCreated: string;
    author?: ShittyUser;
    dealer: ShittyDealer;
    detailer: ShittyDealer;
    qrCodeCarRel?: {
        id: string;
        car?: ResponseCar;
        author?: ShittyUser;
    };
}

export interface QRCode {
    id: number;
    code: string;
    createdAt: Date;
    dealer: Dealer;
    detailer: Dealer;
    car?: Car;
    carCodeId?: number;
    author: User;
}
export interface QRCodeCarServiceEvent {
    id: number;
    action: {
        id: number;
        name: string;
        type: string;
        pos: number;
    };
    author: User;
    note: string;
    date: Date;
    type: string;
    lat: number;
    lng: number;
    photoName: string;
    hasKey: boolean;
}
export interface QRCodeCarService {
    id: number;
    events: QRCodeCarServiceEvent[];
    locations: QRCodeCarServiceEvent[];
    lastEvent: QRCodeCarServiceEvent;
    hasKey: boolean;
    heldBy?: {
        name: string;
        date: Date;
    };
}

export interface InventoryResponse {
    id: number;
    controlInventory: number;
    totalInventory: number;
    estado: number;
    fecha: string;
    dealer: ShittyDealer;
    detailer: ShittyDealer;
    author: ShittyUser;
}
export interface Inventory {
    id: number;
    dealer: Dealer;
    detailer: Dealer;
    controlInventory: number;
    totalInventory: number;
    status: number;
    author: User;
    date: string;
}
export const getInventoryFromResponse = (r: InventoryResponse): Inventory => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        dealer: convertResponseDealer(r.dealer),
        detailer: convertResponseDealer(r.detailer),
        controlInventory: r.controlInventory,
        status: r.estado,
        date: r.fecha,
        author: convertShittyUser(r.author),
        totalInventory: r.totalInventory
    }
}

export const getQRCodeFromResponse = (r: ResponseQRCode): QRCode => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        code: r.qrCode,
        createdAt: r.dateCreated ? toDateFromString(r.dateCreated) : null,
        dealer: convertResponseDealer(r.dealer),
        detailer: convertResponseDealer(r.detailer),
        car: r.qrCodeCarRel ? getCarFromResponse(r.qrCodeCarRel.car) : null,
        carCodeId: +r.qrCodeCarRel?.id,
        author: r.qrCodeCarRel ? convertShittyUser(r.qrCodeCarRel.author) : null,
    };
};

export const getServiceFromResponse = (r: any): Service => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre.replace('&amp;', '&'),
        price: r.price,
        piecework: r.piecework,
        employeesPiecework: r.employeesPiecework,
        pos: r.pos ? +r.pos : Infinity,
        qty: r.qty ? +r.qty : 0,
        hasQty: r.hasQty === '1' ? true : false,
        stockNumberRequired: r.hasStockRequired === '1' ? true : false,
        poRequired: r.hasPoRequired === '1' ? true : false,
        roRequired: r.hasRoRequired === '1' ? true : false,
        tagRequired: r.hasTagRequired === '1' ? true : false,
        salesRequired: r.hasSalesRequired === '1' ? true : false,
        stockSemiRequired: r.hasStockSemiRequired === '1' ? true : false,
        roSemiRequired: r.hasRoSemiRequired === '1' ? true : false,
        poSemiRequired: r.hasPoSemiRequired === '1' ? true : false,
        hasPhotoRequired: r.hasPhotoRequired === '1' ? true : false,
        noteRequired: r.hasNoteRequired === '1' ? true : false,
        automaticInvoice: r.hasInvoiceAutomatic === '1' ? true : false,
        showSpotPromise: r.hasShowSpotPromise,
        comment: r.comentario,
        department: getDepartmentFromResponse(r.department),
        fullName: r.department ? `${r.department.nombre}/${r.nombre}` : r.nombre,
        associatedServices: r.servicesAssociates ? r.servicesAssociates.split(',') : [],
        complianceTime: r.complianceTime ? getMillisFromString(r.complianceTime) : 0,
        hasCreateWoEachService: r.hasCreateWoEachService === '1' ? true : false,
        incompatibleServices: r.incompatibleServices ? r.incompatibleServices.map( s => s.id) : [],
        incompatibleServiceOf: r.incompatibleServiceOf ? r.incompatibleServiceOf.map( s => s.id) : [],
        disabled: r.disabled && r.disabled === 1 ? true: false,
    };
};
const minuteMilli = 60000;
const hourMilli = minuteMilli * 60;
export const getMillisFromString = (time: string): number => {
    const parts = time.split(':').slice(0,2);
    return (Number(parts[0])*hourMilli)+(Number(parts[1])*minuteMilli);
}

export const getDepartmentFromResponse = (r: any): Department => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre ? r.nombre : r.detail.nombre,
    };
};

export const convertShittyWorkflow = (r: any): Workflow => {
    if (!r) {
        return null;
    }
    return {
        id: +r.id,
        value: r.descripcion && r.descripcion.replace('In ', ''),
    };
};

export const pad = (n: number) => {
    if (n < 10) {
        return '0' + n;
    }
    return n;
};

export const toStringDate = (date: Date, withoutTime?: boolean): string => {
    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        (!withoutTime ? ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) : '')
    );
};

export const toDateFromString = (date: string): Date => {
    const parts = date.split(' ');
    return new Date(`${parts[0]}T${parts[1]}Z`);
};

export const invoiceToRequest = (i: Invoice, userId: number, carId: number, dealerId: number) => {
    return {
        id: i.id,
        author: {
            id: userId,
        },
        car: {
            id: carId,
        },
        dealerProvider: {
            id: i.provider?.id,
        },
        ro: i.ro,
        po: i.po,
        tag: i.tag,
        miles: i.miles,
        fechaAlta: i.entryDate ? toStringDate(i.entryDate) : null,
        promiseDatetime: i.promisedDate ? toStringDate(i.promisedDate) : null,
        uPromiseDatetime: i.promisedDate ? i.promisedDate.getTime() / 1000 : null,
        observation: i.observation,
        priority: i.priority,
        status: i.status,
        wo: i.workOrder,
        workflow: i.workflow,
        department: i.department,
        salesman: i.salesman,
        stockNumber: i.stockNumber,
        invoiceServices: i.invoiceServices.map((ii) => {
            return {
                invoiceService: { id: ii.id },
                price: ii.price,
                piecework: ii.piecework,
                employeesPiecework: ii.employeesPiecework,
                pos: ii.pos,
                qty: ii.qty,
                hasQty: ii.hasQty,
                comment: ii.comment,
                ro: ii.ro,
                po: ii.po,
            };
        }),
        prioridad: 1,
        damage: JSON.stringify(i.damage),
        employees: i.employees,
        phone: i.phone,
        advisor: {id: i.advisor?.id, nombre: i.advisor?.nombre, phone: i.advisor?.phone, email: i.advisor?.email},
        technician: {id: i.technician?.id, nombre: i.technician?.nombre},
        spot: i.spot
    };
};

export const convertFromShittyParameter = (r: ShittyParameter): Parameter => {
    if (!r) {
        return { value: '' };
    }
    return {
        id: r.id ? +r.id : +r.idDato,
        value: r.nombre,
    };
};

export const convertToShittyParameter = (r: Parameter): ShittyParameter => {
    if (!r) {
        return null;
    }
    return {
        idDato: r.id,
        nombre: r.value,
    };
};

export const carToRequest = (car: Car): RequestCar => {
    if (!car) {
        return null;
    }
    return {
        ...car.responseCar,
        tipo: car.type,
        year: car.year,
        color: car.color,
        make: convertToShittyParameter(car.maker),
        model: convertToShittyParameter(car.model),
        stockNumber: car.stockNumber,
        vin: car.vinNumber,
    };
};

export const convertResponseDealer = (r: ShittyDealer, permisos?: { id: string }[]): Dealer => {
    if (!r) {
        return null;
    }
    permisos = permisos ? permisos : r.permisos;
    let ktaActions;
    if (r.ktaActions) {
        ktaActions = {};
        r.ktaActions.forEach((a) => {
            ktaActions[+a.id] = { label: a.actionName };
        });
    }
    let serviceGroups;
    if(r.serviceGrouping){
        serviceGroups = {};
        r.serviceGrouping.forEach( sg => {
            serviceGroups[sg.serviceDestination.id] = sg.servicesAgruped.map( sd => {
                return +sd.id;
            })
        })
    } 

    return {
        id: r.id,
        name: r.razonSocial ? r.razonSocial : (r as any).name,
        logo: checkLogo(r.logoImg),
        permissions: permisos && permisos.map((p) => +p.id),
        woImageEnabled: r.isImgRequired === '1' ? true : false,
        stockNumberRequired: r.hasStockRequired === '1' ? true : false,
        poRequired: r.hasPoRequired === '1' ? true : false,
        roRequired: r.hasRoRequired === '1' ? true : false,
        tagRequired: r.hasTagRequired === '1' ? true : false,
        salesRequired: r.hasSalesRequired === '1' ? true : false,
    	ktaActions,
        visibleFields: {
            isMilesVisible: r.isMilesVisible === '1' ? true : false,
            isPhotoVisible: r.isPhotoVisible === '1' ? true : false,
            isPoVisible: Number(r.isPoVisible) ? true : false,
            isRoVisible: Number(r.isRoVisible) ? true : false,
            isSalespersonVisible: r.isSalespersonVisible === '1' ? true : false,
            isStockVisible: r.isStockVisible === '1' ? true : false,
            isTagVisible: r.isTagVisible === '1' ? true : false,
        },
        keyTrackerEnabled: Number(r.haskeyTracker) === 1 ? true : false,
        hasSalesmanRequired: Number(r.hasSalesmanRequired) ? true : false,
        hasSalespersonEmailReq: Number(r.hasSalespersonEmailReq) ? true : false,
        hasSalespersonPhoneEmail: Number(r.hasSalespersonPhoneEmail) ? true : false,
        hasSalespersonPhoneReq: Number(r.hasSalespersonPhoneReq) ? true : false,
        hasSalespersonWithPhone: Number(r.hasSalespersonWithPhone) ? true : false,
        hasNoteRequiredInternalUser: Number(r.hasNoteRequiredInternalUser) ? true : false,
        hasNoteUnapprove: Number(r.hasNoteUnapprove) ? true : false,
        hasSecuentialWorkflow: Number(r.hasSecuencialWorkflow) === 1 ? true : false,
        hasFinalInspect: Number(r.hasFinalInspect) === 1 ? true : false,
        hasInternalInspect: Number(r.hasInternalInspect) === 1 ? true : false,
        isStatusVisible: r.isStatusVisible === 0 ? false : true,
        hasQuickWo: r.hasQuickWo === 1 ? true : false,
        hasGPSConfigEnabled: Number(r.hasGPSConfigEnabled) === 1 ? true : false,
        serviceGroups,
    };
};

export const getInspectionHistoryFromResponse = (r: any): InspectionHistory => {
    if (!r) {
        return null;
    }
    return {
        date: toDateFromString(r.fecha),
        lat: r.lat,
        lng: r.lng,
        note: r.note,
        signatureFile: r.signatureNameFile,
        signaturePath: r.signatureNameFileFullPath,
        type: +r.type,
        user: convertShittyUser(r.author),
        status: +r.inspected,
    }
}

export const getInvoiceFromResponse = (r: any): Invoice => {
    if (!r) {
        return null;
    }
    let hasAddedService = false;
    let promisedDate = r.uPromiseDatetime && new Date(Number(r.uPromiseDatetime) * 1000);
    let promisedDateColor;
    if (promisedDate) {
        const promisedTime = promisedDate.getTime();
        const currentTime = Date.now();
        const hours = (currentTime - promisedTime) / 1000 / 60 / 60;
        promisedDateColor = hours > 24 ? 'black' : hours >= 12 ? 'orange' : 'red';
    }
    return {
        id: r.id,
        idInvoice: r.idInvoice ? +r.idInvoice : 0,
        ro: r.ro,
        po: r.po,
        tag: r.tag,
        miles: r.miles,
        entryDate: r.dateTimestamp ? new Date(r.dateTimestamp * 1000) : toDateFromString(r.fechaAlta),
        promisedDate,
        promisedDateColor,
        observation: r.observation,
        priority: r.priority,
        status: r.status,
        workOrder: r.wo,
        fullWo: r.fullNro,
        workflow: convertShittyWorkflow(r.workflow),
        dealerProvider: convertResponseDealer(r.dealerProvider),
        department: {
            id: r.department.id,
            name: r.department.nombre,
            dealer: convertResponseDealer(r.department.dealer),
        },
        services: r.invoiceServicesNames
            ? r.invoiceServicesNames.split(',').map((s) => {
                  const parts = s.split('|');
                  if (parts.length > 1) {
                      return {
                          id: +parts[2],
                          name: parts[0],
                          note: parts[1],
                          user: parts[3],
                          date: parts[4],
                      };
                  } else {
                      return {
                          name: s,
                      };
                  }
              })
            : [],
            fuelString: r.invoiceServicesNames
            ? r.invoiceServicesNames
                  .split(',')
                  .map((s) => {
                      const parts = s.split('|');
                      if (parts.length > 1) {
                          if (parts.length > 4) {
                                const gal = +parts[6];
                                const value = r.total;
                              return `<span>${parts[0]} ${gal ? '/ Gal: '+gal:''} ${value ? '/ $'+value : ''}</span>`;   
                          }
                      } else {
                          return s;
                      }
                  })
                  .join(',')
            : '',
        servicesString: r.invoiceServicesNames
            ? r.invoiceServicesNames
                  .split(',')
                  .map((s) => {
                      const parts = s.split('|');
                      if (parts.length > 1) {
                          if (parts.length > 4) {
                              if (parts[5] && parts[5] !== '1') {
                                  hasAddedService = true;
                                  return `<span style="color: var(--ion-color-primary)">${parts[0]} ${
                                      parts[1] ? '(' + parts[1] + ')' : ''
                                  }</span>`;
                              } else {
                                  return `<span>${parts[0]} ${parts[1] ? '(' + parts[1] + ')' : ''}</span>`;
                              }
                          } else {
                              return `<span>${parts[0]} ${parts[1] ? '(' + parts[1] + ')' : ''}</span>`;
                          }
                      } else {
                          return s;
                      }
                  })
                  .join(',')
            : '',
        salesman: r.salesman,
        createdBy: r.author && r.author.nombre,
        approvedBy: r.approver && r.approver.nombre,
        provider: { id: r.dealerProvider.id },
        employeeIds: r.employeesIds ? r.employeesIds.split(',').map((v) => Number(v)) : [],
        invoiceServices:
            r.invoiceServices &&
            r.invoiceServices.map((iv) => ({
                id: iv.invoiceService.id,
                name: iv.invoiceService.nombre,
                invoicePrice: iv.invoiceService.price,
                price: iv.price,
                piecework: iv.piecework,
                employeesPiecework:
                    iv.employeesPiecework &&
                    iv.employeesPiecework.map((ep) => ({
                        idService: iv.invoiceService.id,
                        idEmployee: ep.idEmployee,
                        piecework: ep.piecework,
                        id: ep.id,
                        estado: ep.estado,
                    })),
                pos: iv.pos && +iv.pos,
                qty: iv.qty !== '0' ? +iv.qty : 0,
                hasQty: iv.hasQty === '1' ? true : false,
                comment: iv.comentario,
                associatedServices: iv.servicesAssociates ? iv.servicesAssociates.split(',') : [],
            })),
        damage: r.damage ? JSON.parse(r.damage) : null,
        employees: r.employees ? r.employees.map((e) => ({ id: e.id })) : null,
        accessEdit: r.accessEdit,
        isApproved: r.isApproved,
        allowApprove: r.allowApprove,
        car: r.car ? getCarFromResponse(r.car) : null,
        stockNumber: r.stockNumber,
        images: Number(r.haveImage),
        selected: false,
        woImage: +r.haveWoImage ,
        isBilled: r.isBilled === '1' ? true : false,
        pauseNote: r.pauseNote,
        totalCost: r.total ? +r.total : 0,
        phone: r.phone ? r.phone : { email: '', phone: null, phoneMask: '', nombre: '' },
        hasAddedService,
        advisor: getEmployeeFromRequest(r.advisor),
        technician: getEmployeeFromRequest(r.technician),
        isInspected: r.isInspected,
        isInternalInspected: r.isInternalInspected,
        unapprovedReason: r.unApprovedNote,
        spot: r.hasSpot === '1' ? true : false,
    };
};

export const checkLogo = (logo: string) => {
    if (!logo || logo.includes('emptylogo')) {
        return null;
    } else {
        return logo;
    }
};

export const convertResponseRole = (r: ShittyRole): Role => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        name: r.nombre,
        dealer: convertResponseDealer(r.dealer),
        permissions: r.permisos && r.permisos.map((p) => +p.id),
    };
};

export const convertShittyUser = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    let roles;
    let dealers;
    let provider;
    let providerMap;
    if (r.roles) {
        roles = r.roles.map((rl) => convertResponseRole(rl));
        if (r.isDetailer) {
                dealers = r.rolesRel.map((rl) => convertResponseDealer(rl.dealerAsigned));
                provider = r.rolesRel.map((rl) => convertResponseDealer(rl.rol.companiaOrigen, rl.rol.permisos));
        } else {
            if(r.isUserMultiDealer){
                dealers = r.roles.map((rl) => convertResponseDealer(rl.dealer));
                provider = r.rolesRel.map((rl) => convertResponseDealer(rl.rol.companiaOrigen, rl.rol.permisos));
                providerMap = {};
                for (let i = 0; i < r.rolesRel.length; i++) {
                    const e = r.rolesRel[i];
                    if(!providerMap[+e.rol.dealer.id]){
                        providerMap[+e.rol.dealer.id]= [];
                    }
                    e.rol.dealer.providers.forEach( p => {
                        providerMap[+e.rol.dealer.id].push(convertResponseDealer(p));
                    });
                }
            } else {
                dealers = r.rolesRel.map((rl) => convertResponseDealer(rl.rol.dealer, rl.rol.permisos)).slice(0, 1);
                provider = r.rolesRel.map((rl) => convertResponseDealer(rl.rol.companiaOrigen, rl.rol.permisos)).slice(0, 1);
            }
        }
    }

    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles,
        logo: r.logoImg,
        dealers,
        isDetailer: r.isDetailer,
        token: r.token,
        provider,
        ownerLogo: r.contratistaOwner?.logoImg,
        isUserMultiDealer: r.isUserMultiDealer,
        providerMap,
        phone: r.phone,
        phoneMask: r.phoneMask,
        isFuelModeEnabled: r.hasFuelCompany === '1' ? true : false,
    };
};

export const getEmployeeFromRequest = (r: ShittyUser): User => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        internalCode: r.codigoInterno,
        name: r.nombre,
        email: r.email,
        roles: null,
        logo: null,
        dealers: null,
        isDetailer: null,
        token: null,
        provider: null,
        isChecked: false,
        ownerLogo: r.contratistaOwner?.logoImg,
        isUserMultiDealer: r.isUserMultiDealer,
        providerMap: null,
        phone: r.phone,
        phoneMask: r.phoneMask,
        isFuelModeEnabled: r.hasFuelCompany === '1' ? true : false,
    };
};

export const getEmailFromResponse = (r: any): Email => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        description: r.descripcion,
        emails: r.emails.map((e) => e.email),
        sent: new Date(r.fecha),
        files: r.files.map((f) => ({
            name: f.nameFile,
            queue: f.emailQueue,
            description: f.descripcion,
            extension: f.extension,
            id: f.id,
        })),
        subject: r.asunto,
    };
};

export const getCarFromResponse = (r: ResponseCar): Car => {
    if (!r) {
        return null;
    }
    return {
        id: r.id,
        vinNumber: r.vin && r.vin.toUpperCase(),
        shortVin: r.vin && r.vin.slice(8, 17),
        year: r.year,
        maker: convertFromShittyParameter(r.make),
        model: convertFromShittyParameter(r.model),
        color: r.color,
        responseCar: r,
        stockNumber: r.stockNumber ? r.stockNumber.toUpperCase() : null,
        type: r.tipo,
        isTouch: !!+r.isTouch,
        lat: +r.lat,
        lng: +r.lng,
        lastLocationDate: r.lastLocationDate ? toDateFromString(r.lastLocationDate) : null,
        authorLastLocation: convertShittyUser(r.authorLastLocation),
    	photo: r.carPhotoMain,
        qrService: r.qrService
            ? {
                  id: +r.qrService.id,
                  events: r.qrService.events ? r.qrService.events.map((s) => getQREventFromResponse(s)) : [],
                  locations: r.qrService.locations ? r.qrService.locations.map((s) => getQREventFromResponse(s)) : [],
                  lastEvent: r.qrService.usrLastEvent ? getQREventFromResponse(r.qrService.usrLastEvent) : null,
                  hasKey: r.qrService ? r.qrService.haveKey : false,
                  heldBy:
                      r.qrService && r.qrService.heldBy
                          ? {
                                name: r.qrService.heldBy.nombre,
                                date: toDateFromString(r.qrService.heldBy.fecha),
                            }
                          : null,
              }
            : { id: null, events: null, locations: null, lastEvent: null, hasKey: false, heldBy: null },
        hasKey: r.statusKey && r.statusKey === '1' ? true : false,
    };
};

export const getQREventFromResponse = (s): QRCodeCarServiceEvent => {
    if (!s) {
        return null;
    }
    return {
        action: {
            id: +s.action.id,
            name: s.action.actionName,
            pos: +s.action.pos,
            type: s.action.typeEvent,
        },
        author: convertShittyUser(s.author),
        date: s.fecha ? toDateFromString(s.fecha) : null,
        hasKey: s.hasKey && s.hasKey === '1',
        photoName: s.photoName,
        id: +s.id,
        lat: +s.lat,
        lng: +s.lng,
        note: s.note,
        type: s.type,
    };
};
