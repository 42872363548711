import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Service } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-edit-service-piecework',
    templateUrl: './edit-service-piecework.component.html',
    styleUrls: ['./edit-service-piecework.component.scss'],
})
export class EditServicePieceworkComponent implements OnInit {
    services: Service[];
    title = 'SERVICE_PIECEWORK';
    sub_title: string;
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    confirm() {
        this.dismiss({ services: this.services });
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
