import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-damage',
  templateUrl: './damage.page.html',
  styleUrls: ['./damage.page.scss'],
})
export class DamagePage implements OnInit {

  damage: any;
  canEdit: boolean;
  constructor(
    public modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    if (!this.damage) {
      this.clearDamage();
    }
  }

  clearDamage() {
    this.damage = [];
    for (let i = 0; i < 14; i++) {
        this.damage.push([false, false, false, false, false, false, false, false]);
      }
  }

  setActive(y, x) {
    if ((y >= 2 && y <= 11) || ((y <= 2 || y >= 11) && (x >= 2 && x <= 5))) {
      this.damage[y][x] = !this.damage[y][x];
    }
  }

  save() {
    this.dismiss({damage: this.damage});
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

}
