import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
    @Input() imgSource = [];
    @Input() pos = 1;
    slideOpts = null;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        setTimeout(() => {
            this.slideOpts = {
                centeredSlides: 'true',
                initialSlide: this.pos,
            };
        });
    }

    closeModal() {
        this.modalController.dismiss();
        this.slideOpts = null;
    }
}
