import { Component, Inject, ViewChild } from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    IonRouterOutlet,
    MenuController,
    NavController,
    Platform,
    ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from './app.config';
import OneSignal from 'onesignal-cordova-plugin';
import { NotificationService } from './providers/notification-service/notification.service';
import { StatusBar } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { StorageService } from './services/storage.service';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { AuthService } from './providers/auth-service/auth.service';
import { GlobalEvents } from './services/events.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { User } from '../../../srs-common/src/models';
import { InformationService } from './providers/information-service/information.service';
import { LocalNotifications } from '@capacitor/local-notifications';
import OSNotification from 'onesignal-cordova-plugin/dist/OSNotification';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    isKeyboardActive = false;
    isUpdateAlertOpen = false;
    user: User;
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    version: string;
    hasProductionReportPermission = false;
    hasPieceworkReportPermission = false;
    hasInventoryPermission = false;
    hasExpensesPermission = false;
    hasExpensesAdminPermission = false;
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private platform: Platform,
        private storage: StorageService,
        public translate: TranslateService,
        private navCtrl: NavController,
        private notificationService: NotificationService,
        private events: GlobalEvents,
        private alertController: AlertController,
        private router: Router,
        private authService: AuthService,
        private actionSheetCtrl: ActionSheetController,
        private informationService: InformationService,
        private menuCtrl: MenuController,
        private toastCtrl: ToastController
    ) {
        translate.addLangs(['es', 'en']);
        this.events.getObservable().subscribe((e) => this.handleGlobalEvents(e));
        this.initializeApp();
    }

    async initializeApp() {
        await this.storage.init();
        const lang = await this.storage.get('lang', 'en');
        this.translate.setDefaultLang(lang);
        const platform = await this.platform.ready();
        this.version = this.config.appVersion;
        try {
            AuthService.token = await this.storage.get('token');
            const deviceInfo = await Device.getInfo();
            AuthService.deviceModel = `${deviceInfo.manufacturer} - ${deviceInfo.model} - ${deviceInfo.osVersion}`;
            const uuid = (await Device.getId()).uuid;
            if (platform === 'dom') {
                await this.storePushId(uuid);
            } else {
                /// ONE SIGNAL SETUP
                OneSignal.setAppId(this.config.pushAppId);
                OneSignal.setNotificationWillShowInForegroundHandler((event) => {
                    const notification = event.getNotification();
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title,
                                body: notification.body,
                                sound: notification.sound,
                                extra: notification,
                                id: Date.now(),
                            },
                        ],
                    });
                    event.complete(null);
                });
                OneSignal.setNotificationOpenedHandler((event) => {
                    if (!event.action.type) {
                        this.handleNotifications(event.notification);
                    }
                });
                OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                    console.log('User accepted notifications: ' + accepted);
                });
                (OneSignal.getDeviceState as any)(async (state) => {
                    await this.storePushId(state.userId);
                });
                OneSignal.clearOneSignalNotifications();
                // this.oneSignal.startInit(this.config.pushAppId, this.config.pushGoogleId);
                // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
                // this.oneSignal.getIds().then(async (data) => {
                //     await this.storePushId(data.userId);
                // });
                // this.oneSignal.clearOneSignalNotifications();
                /// ONE SIGNAL SETUP
                StatusBar.setOverlaysWebView({ overlay: false });
                StatusBar.setBackgroundColor({ color: '#87B93A' });

                Keyboard.addListener('keyboardWillShow', () => {
                    this.toggleFooter(true);
                });

                Keyboard.addListener('keyboardWillHide', () => {
                    this.toggleFooter(false);
                });
                this.platform.backButton.subscribeWithPriority(-1, () => {
                    if (!this.routerOutlet.canGoBack()) {
                        App.exitApp();
                    }
                });
                LocalNotifications.addListener('localNotificationActionPerformed', (event) => {
                    if (event.actionId) {
                        this.handleNotifications(event.notification.extra);
                    }
                });
            }
        } catch (e) {}
    }

    async storePushId(pushId: string) {
        await this.storage.set('pushId', pushId);
        AuthService.pushId = pushId;
    }
    handleNotificationsReceived(data: OSNotification) {}
    handleNotifications(data: OSNotification) {
        const additionalData = data.additionalData as any;
        if (additionalData && additionalData.typeNotif === 1) {
            setTimeout(() => {
                this.notificationService.markNotificationsRead(+additionalData.idNotification);
                if (this.router.url.startsWith('/listado-invoice')) {
                    this.events.publish({ event: 'WO_NOTIFICATION', data: additionalData.nroWo });
                } else {
                    this.navCtrl.navigateForward('/listado-invoice/0?id=' + additionalData.nroWo);
                }
            }, 300);
        }
        if (additionalData && additionalData.typeNotif === 2 && additionalData.link) {
            setTimeout(async () => {
                this.notificationService.markNotificationsRead(+additionalData.idNotification);
                Browser.open({ url: additionalData.link });
            }, 300);
        }
    }

    async handleGlobalEvents(e: { event: string; data: any }) {
        if (e.event === 'NEW_UPDATE') {
            if (!this.isUpdateAlertOpen) {
                const t = await this.translate.get(['UPDATE_TITLE', 'UPDATE_TEXT', 'UPDATE_BUTTON']).toPromise();
                const alert = await this.alertController.create({
                    header: t.UPDATE_TITLE,
                    message: t.UPDATE_TEXT,
                    backdropDismiss: false,
                    buttons: [
                        {
                            text: t.UPDATE_BUTTON,
                            handler: () => {
                                let storeUrl;
                                if (this.platform.is('android')) {
                                    storeUrl = this.config.playStore;
                                } else if (this.platform.is('ios')) {
                                    storeUrl = this.config.appStore;
                                }
                                if (storeUrl) {
                                    Browser.open({
                                        url: storeUrl,
                                        windowName: '_system',
                                    });
                                    // this.browser.create(storeUrl);
                                    this.isUpdateAlertOpen = false;
                                }
                            },
                        },
                    ],
                });
                await alert.present();
                this.isUpdateAlertOpen = true;
            }
        }
    }

    toggleFooter(state: boolean) {
        const footers = document.querySelectorAll('ion-footer');
        if (footers) {
            footers.forEach((f) => {
                f.classList.toggle('hide', state);
            });
        }
    }

    /// MENU

    async preMenuOpen() {
        this.user = await this.authService.getUser();
        const permissions = await this.authService.getPermissions();
        this.hasProductionReportPermission = permissions?.includes(47);
        this.hasPieceworkReportPermission = permissions?.includes(51) || permissions?.includes(91);
        this.hasInventoryPermission = permissions?.includes(108);
        this.hasExpensesPermission = permissions?.includes(110);
        this.hasExpensesAdminPermission = permissions?.includes(111);
    }

    presentActionSheet() {
        this.translate.get(['LANGUAGE', 'ENGLISH', 'SPANISH', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                header: res.LANGUAGE,
                buttons: [
                    {
                        text: res.ENGLISH,
                        handler: () => {
                            this.changeLang('en');
                        },
                    },
                    {
                        text: res.SPANISH,
                        handler: () => {
                            this.changeLang('es');
                        },
                    },
                    {
                        text: res.CANCEL,
                        role: 'cancel',
                    },
                ],
            });
            actionSheet.present();
        });
    }

    async logout() {
        const t = await this.translate.get(['LOGOUT', 'CANCEL', 'ACCEPT']).toPromise();
        const alert = await this.alertController.create({
            header: t.LOGOUT,
            buttons: [
                {
                    text: t.CANCEL,
                    role: 'cancel',
                },
                {
                    text: t.ACCEPT,
                    handler: async () => {
                        await this.authService.logout();
                        this.menuCtrl.close();
                        this.navCtrl.navigateRoot('login');
                    },
                },
            ],
        });
        await alert.present();
    }

    async showToast(header: string, message: string, buttonText: string, handler?) {
        const toast = await this.toastCtrl.create({
            duration: 3000,
            position: 'top',
            header,
            message,
            buttons: [
                {
                    side: 'end',
                    text: buttonText,
                    handler,
                },
            ],
        });
        await toast.present();
    }

    async changeDealer() {
        this.menuCtrl.close();
        this.events.publish({ event: 'CHANGE_DEALER', data: null });
    }

    goToEmails() {
        this.navigate('/email');
    }

    changeLang(lang) {
        this.informationService.changeLang(lang);
    }

    async goToSearch() {
        if (!this.router.url.startsWith('/home')) {
            await this.navCtrl.navigateRoot('/home');
        }
        setTimeout(() => {
            this.navigate('/buscar-vehiculo');
        });
    }

    goToWO() {
        this.navigate('/listado-invoice/0');
    }

    goToPieceworkReport() {
        this.navigate('/piecework-report');
    }

    goToProductionReport() {
        this.navigate('/production-report');
    }

    goToInventory() {
        this.navigate('/inventory');
    }

    goToExpenses() {
        this.navigate('/expenses');
    }

    goToHome() {
        this.menuCtrl.close();
        if (!this.router.url.startsWith('/home')) {
            this.navCtrl.navigateRoot('/home');
        }
    }

    goToFuelHome() {
        this.menuCtrl.close();
        if (!this.router.url.startsWith('/fuel-home')) {
            this.navCtrl.navigateRoot('/fuel-home');
        }
    }

    navigate(route: string) {
        this.menuCtrl.close();
        if (!this.router.url.startsWith(route)) {
            this.navCtrl.navigateForward(route);
        }
    }

    /// MENU
}
