import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SearchModal } from '../search.helper';
import { Expense, ExpensesService } from 'src/app/providers/expenses-service/expenses.service';
import { DatetimeOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { PhotoService } from 'src/app/services/photo.service';
import { ImageViewerComponent } from 'src/app/pages/image-viewer/image-viewer.component';

@Component({
    selector: 'app-new-expense',
    templateUrl: './new-expense.component.html',
    styleUrls: ['./new-expense.component.scss'],
})
export class NewExpenseComponent implements OnInit {
    categories: { id: number; name: string }[] = [];
    companies: { id?: number; name: string }[] = [];
    selectedCategory = '';
    selectedCompany = '';
    company;
    category;
    notes = '';
    date = '';
    amount;
    searchModal: SearchModal;
    customPickerOptions: DatetimeOptions;
    expense?: Expense;
    disableEdit = false;
    ticketImage?: string;
    isApproved = null;
    isAdmin = false;
    ticketImages: { url: string }[] = [];

    constructor(
        private modalCtrl: ModalController,
        private expensesService: ExpensesService,
        private translate: TranslateService,
        private photoService: PhotoService,
        private alertCtrl: AlertController
    ) {
        const t = new Date();
        const z = t.getTimezoneOffset() * 60 * 1000;
        const tLocal = t.getTime() - z;
        this.date = new Date(tLocal).toISOString();
    }

    async ngOnInit() {
        if (this.expense) {
            this.disableEdit = true;
            this.category = this.expense.category;
            this.selectedCategory = this.expense.category.name;
            this.company = this.expense.company;
            this.selectedCompany = this.expense.company.name;
            this.notes = this.expense.notes;
            this.date = this.expense.ticketDate;
            this.amount = this.expense.amount;
            this.isApproved = this.expense.isApproved;
            this.expensesService.getExpenseImages(this.expense).then((images) => {
                this.ticketImages = images;
            });
        }
        this.translate.get(['ACCEPT', 'CANCEL', 'CLEAR']).subscribe((resp: any) => {
            this.customPickerOptions = {
                buttons: [
                    {
                        role: 'cancel',
                        text: resp.CANCEL,
                    },
                    {
                        text: resp.CLEAR,
                        handler: () => {
                            this.date = null;
                        },
                    },
                    {
                        text: resp.ACCEPT,
                        handler: (date) => {
                            const newDate = `${date.year.text}-${date.month.text}-${date.day.text}`;
                            this.date = newDate;
                        },
                    },
                ],
            };
        });
        this.companies = await this.expensesService.getCompanies();
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    async takeTicketPicture() {
        this.ticketImage = await this.photoService.presentActionSheet();
        if (this.ticketImage) this.ticketImages.push({ url: this.ticketImage });
    }

    async handleFileSelect($evt) {
        this.ticketImage = await this.photoService.handleFileSelect($evt);
        this.ticketImages.push({ url: this.ticketImage });
    }

    async save() {
        if (!this.company) {
            this.showError('COMPANY_REQUIRED');
            return;
        } else if (!this.category) {
            this.showError('CATEGORY_REQUIRED');
            return;
        } else if (!this.ticketImage) {
            this.showError('TICKET_IMAGE_REQUIRED');
            return;
        } else if (!this.date) {
            this.showError('TICKET_DATE_REQUIRED');
            return;
        } else if (!this.amount) {
            this.showError('TICKET_AMOUNT_REQUIRED');
            return;
        }
        const id = await this.expensesService.saveExpense(
            this.company,
            this.category,
            1,
            1,
            this.date,
            this.amount,
            this.notes
        );
        if (this.ticketImage) {
            await this.expensesService.uploadTicketImage(id, this.ticketImage);
        }
        this.dismiss();
    }

    async selectCompany() {
        if (this.disableEdit) {
            return;
        }
        const company = await this.searchModal.selectParameter({
            sourceList: this.companies.map((c) => ({ id: +c.id, value: c.name })),
            initSelected: this.company ? +this.company.id : null,
            enableCreation: true,
        });
        if (company) {
            this.company = { id: company.id, name: company.value };
            this.selectedCompany = company.value;
        }
    }

    async selectCategory() {
        if (this.disableEdit) {
            return;
        }
        const category = await this.searchModal.selectParameter({
            sourceList: this.categories.map((c) => ({ id: +c.id, value: c.name })),
            initSelected: this.category ? +this.category.id : null,
            // enableCreation: true,
        });
        if (category) {
            this.category = { id: category.id, name: category.value };
            this.selectedCategory = category.value;
        }
    }

    async approveExpense() {
        const text = await this.translate.get(['OK', 'CANCEL', 'APPROVE_EXPENSE_MSG']).toPromise();
        const approveAlert = await this.alertCtrl.create({
            message: text.APPROVE_EXPENSE_MSG,
            buttons: [
                {
                    text: text.CANCEL,
                    role: 'cancel',
                },
                {
                    text: text.OK,
                    handler: async () => {
                        this.dismiss({ event: 'APPROVE', expense: this.expense });
                    },
                },
            ],
        });
        await approveAlert.present();
    }
    async disapproveExpense() {
        const text = await this.translate.get(['OK', 'CANCEL', 'DISAPPROVE_EXPENSE_MSG']).toPromise();
        const approveAlert = await this.alertCtrl.create({
            message: text.DISAPPROVE_EXPENSE_MSG,
            buttons: [
                {
                    text: text.CANCEL,
                    role: 'cancel',
                },
                {
                    text: text.OK,
                    handler: async () => {
                        this.dismiss({ event: 'REJECT', expense: this.expense });
                    },
                },
            ],
        });
        await approveAlert.present();
    }

    showError(error: string) {
        this.translate.get([error, 'OK']).subscribe(async (resp) => {
            const alertError = await this.alertCtrl.create({
                message: resp[error],
                buttons: [
                    {
                        text: resp.OK,
                        role: 'accept',
                    },
                ],
            });
            await alertError.present();
        });
    }
    async viewTicketImage() {
        const modal = await this.modalCtrl.create({
            component: ImageViewerComponent,
            componentProps: { imgSource: this.ticketImages, pos: 0 },
            swipeToClose: false,
        });
        await modal.present();
    }

    async deleteTicketImage() {
        const text = await this.translate.get(['OK', 'CANCEL', 'DELETE_PHOTO']).toPromise();
        const approveAlert = await this.alertCtrl.create({
            message: text.DELETE_PHOTO,
            buttons: [
                {
                    text: text.CANCEL,
                    role: 'cancel',
                },
                {
                    text: text.OK,
                    handler: async () => {
                        this.ticketImage = null;
                        this.ticketImages = [];
                    },
                },
            ],
        });
        await approveAlert.present();
    }
}
