import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Car } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-car-list',
    templateUrl: './car-list.component.html',
    styleUrls: ['./car-list.component.scss'],
})
export class CarListComponent implements OnInit {
    sourceList: Car[] = [];
    selected: Car;
    newEnabled: boolean;
    newCar: Partial<Car> = { id: -1 };
    selectDisabled: boolean;
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    radioGroupChange(event) {
        if (event.detail.value == '-1') {
            this.selected = this.newCar as Car;
        } else {
            this.selected = this.sourceList.find((e) => +e.id === +event.detail.value);
        }
    }

    selectObj() {
        if (this.selected) {
            this.dismiss({ car: this.selected });
        } else {
            this.dismiss();
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
