import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-wo-popover',
    templateUrl: './wo-popover.component.html',
    styleUrls: ['./wo-popover.component.scss'],
})
export class WoPopoverComponent implements OnInit {
    showFilterOption = true;
    showInspectionHistory = false;
    constructor(private popoverController: PopoverController) {}

    ngOnInit() {}

    viewVehicle() {
        this.dismissPopover({ event: 'VEHICLE_INFO' });
    }

    viewKey() {
        this.dismissPopover({ event: 'KEY_INFO' });
    }

    inspectionHistory() {
        this.dismissPopover({ event: 'INSPECTION_HISTORY' });
    }

    dismissPopover(data?: any) {
        this.popoverController.dismiss(data);
    }
}
