import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalEvents } from 'src/app/services/events.service';

@Component({
    selector: 'app-view-location',
    templateUrl: './view-location.component.html',
    styleUrls: ['./view-location.component.scss'],
})
export class KtaViewLocationComponent implements OnInit {
    title: string;
    coords: { lat: number; lng: number };
    vehicleCoords: { lat: number; lng: number };
    options: google.maps.MapOptions = {
        disableDefaultUI: true,
        mapTypeId: 'hybrid',
        tilt: 0,
        styles: [
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ],
    };
    disableSet = false;
    constructor(private events: GlobalEvents, private modalCtrl: ModalController) {
        this.events.getObservable().subscribe((e) => {
            if (e.event === 'LOCATION_UPDATE') {
                this.coords = { lat: e.data.latitude, lng: e.data.longitude };
            }
        });
    }

    ngOnInit() {}

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    confirm() {
        if (this.disableSet) {
            this.dismiss();
        } else {
            this.dismiss({
                coords: {
                    lat: this.coords.lat,
                    lng: this.coords.lng,
                },
            });
        }
    }
}
