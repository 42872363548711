import { Component, NgZone, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GpsModule } from 'src/app/gps-module.service';
import { AuthService } from 'src/app/providers/auth-service/auth.service';
import { InformationService } from 'src/app/providers/information-service/information.service';
import { GlobalEvents } from 'src/app/services/events.service';
import { confirmAction, showAlert } from 'src/app/utils';
import { Car } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-gps-module',
    templateUrl: './gps-module.component.html',
    styleUrls: ['./gps-module.component.scss'],
})
export class GpsModuleComponent implements OnInit {
    gpsModule: GpsModule;
    subscription: Subscription;
    values = {
        ssid: null,
        pass: null,
        url: null,
        dealer: null,
        provider: null,
        car: null,
        token: null,
        active: null,
        gps: null,
        wifi: null,
        version: null,
    };
    loading: HTMLIonLoadingElement;
    remoteGPSUrl: string;
    remoteGPSToken: string;
    vehicle: Car;

    constructor(
        private events: GlobalEvents,
        private zone: NgZone,
        private translate: TranslateService,
        private alertCtrl: AlertController,
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private informationService: InformationService,
        private authService: AuthService
    ) {
        this.subscription = this.events.getObservable().subscribe(async (e) => {
            if (e.event === 'GPS_DEVICE_CONNECTED') {
                this.gpsModule.isConnected = true;
                this.loading = await this.loadingCtrl.create({ duration: 20000 });
                await this.loading.present();
                this.gpsModule.getModuleInfo();
            } else if (e.event === 'REPORT_PROGRESS') {
                if (this.loading) {
                    this.loading.message = 100 - e.data + '%';
                }
            } else if (e.event === 'GPS_QUEUE_FINISHED') {
                this.loading.dismiss();
                this.values = { ...this.gpsModule.values };
                this.values.url = this.remoteGPSUrl;
                this.values.token = this.remoteGPSToken;
                if (this.values.car && this.values.car !== this.vehicle.id) {
                    confirmAction(
                        {
                            action: 'GPS_DIFFERENT_VEHICLE_HEADER',
                            message: 'GPS_DIFFERENT_VEHICLE_MSG',
                            callback: () => {
                                this.values.car = this.vehicle.id;
                            },
                        },
                        this.translate,
                        this.alertCtrl
                    );
                }
            }
        });
    }

    async ngOnInit() {
        const config = await this.informationService.getGpsConfig();
        this.remoteGPSToken = config.token;
        this.remoteGPSUrl = config.path;
    }

    connect() {
        this.gpsModule = new GpsModule(this.events, this.zone, this.translate, this.alertCtrl);
    }

    refreshWifiStatus() {
        this.gpsModule.getWifiStatus();
    }

    async updateConfig() {
        this.values.car = this.vehicle.id;
        this.values.provider = this.authService.activeProvider.id;
        this.values.dealer = this.authService.activeDealer.id;
        this.loading = await this.loadingCtrl.create({ duration: 20000 });
        await this.loading.present();
        this.gpsModule.updateValues(this.values);
    }

    changeActiveStatus(event) {
        this.values.active = event.detail.checked ? '1' : '0';
    }

    async dismiss(data?: any) {
        const close = await this.gpsModule.disconnect();
        if (close) {
            this.subscription.unsubscribe();
            this.modalCtrl.dismiss(data);
        }
    }
}
