import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        canActivate: [AuthGuard],
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'fuel-home',
        loadChildren: () => import('./fuel/pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'informacion-vehiculo',
        loadChildren: () =>
            import('./pages/informacion-vehiculo/informacion-vehiculo.module').then(
                (m) => m.InformacionVehiculoPageModule
            ),
    },
    {
        canActivate: [AuthGuard],
        path: 'kta-info',
        loadChildren: () =>
            import('./kta/pages/informacion-vehiculo/informacion-vehiculo.module').then(
                (m) => m.InformacionVehiculoPageModule
            ),
    },
    {
        canActivate: [AuthGuard],
        path: 'nuevo-invoice/:id',
        loadChildren: () => import('./pages/nuevo-invoice/nuevo-invoice.module').then((m) => m.NuevoInvoicePageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'listado-invoice/:vehicle',
        loadChildren: () =>
            import('./pages/listado-invoice/listado-invoice.module').then((m) => m.ListadoInvoicePageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'fuel-listado-invoice/:vehicle',
        loadChildren: () =>
            import('./fuel/pages/listado-invoice/listado-invoice.module').then((m) => m.ListadoInvoicePageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'editar-vehiculo',
        loadChildren: () =>
            import('./pages/editar-vehiculo/editar-vehiculo.module').then((m) => m.EditarVehiculoPageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'buscar-vehiculo',
        loadChildren: () =>
            import('./pages/buscar-vehiculo/buscar-vehiculo.module').then((m) => m.BuscarVehiculoPageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'fuel-buscar-vehiculo',
        loadChildren: () =>
            import('./fuel/pages/buscar-vehiculo/buscar-vehiculo.module').then((m) => m.BuscarVehiculoPageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'email',
        loadChildren: () => import('./pages/email/email.module').then((m) => m.EmailPageModule),
    },
    {
        canActivate: [AuthGuard],
        path: 'production-report',
        loadChildren: () =>
            import('./pages/production-report/production-report.module').then((m) => m.ProductionReportPageModule),
    },
    {
        path: 'piecework-report',
        loadChildren: () =>
            import('./pages/piecework-report/piecework-report.module').then((m) => m.PieceworkReportPageModule),
    },
    {
        path: 'fuel-order/:id',
        loadChildren: () => import('./fuel/pages/order/order.module').then((m) => m.OrderPageModule),
    },
    {
        path: 'fuel-listado-order',
        loadChildren: () =>
            import('./fuel/pages/listado-order/listado-order.module').then((m) => m.ListadoOrderPageModule),
    },
    {
        path: 'inventory',
        loadChildren: () => import('./pages/inventory/inventory.module').then((m) => m.InventoryPageModule),
    },
  {
    path: 'expenses',
    loadChildren: () => import('./pages/expenses/expenses.module').then( m => m.ExpensesPageModule)
  },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
