import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { InvoiceService } from 'src/app/providers/invoice-service/invoice.service';
import { Email } from '../../../../../srs-common/src/models';

@Component({
    selector: 'app-email-detail',
    templateUrl: './email-detail.component.html',
    styleUrls: ['./email-detail.component.scss'],
})
export class EmailDetailComponent implements OnInit {
    email: Email;
    constructor(private modalCtrl: ModalController, private invoiceService: InvoiceService) {}

    ngOnInit() {}

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }

    downloadFile(file) {
        Browser.open({ url: this.invoiceService.getDownloadUrl(file, this.email.id), windowName: '_system' });
    }
}
