import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CroppieDirective } from '@gonzaller/angular-croppie-module';
import { CroppieOptions } from 'croppie';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropComponent {
    myImage: string;
    cropImage: any;
    cropImageLow: any;
    imageLoaded = false;

    @ViewChild('croppie')
    croppieDirective: CroppieDirective;
    croppieOptions: CroppieOptions = {
        viewport: { width: 250, height: 250, type: 'circle' },
        enableOrientation: true,
        enableExif: true,
        showZoomer: false,
        maxZoom: 1,
        minZoom: 0.5,
    };
    constructor(public modalCtrl: ModalController) {}
    async save() {
        const img = await this.croppieDirective.croppie.result({
            circle: false,
            type: 'base64',
            quality: 0.75,
            format: 'jpeg',
        });
        this.dismiss({ img });
    }
    rotate() {
        this.croppieDirective.croppie.rotate(90);
    }
    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
