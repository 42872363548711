import { Component, Input, OnInit } from '@angular/core';
import { GlobalEvents } from 'src/app/services/events.service';
import { Invoice, showInvoiceInspectOptions } from '../../../../../../srs-common/src/models';

@Component({
    selector: 'inspection-slide-items',
    templateUrl: './inspection-slide-items.component.html',
    styleUrls: ['./inspection-slide-items.component.scss'],
})
export class InspectionSlideItemsComponent implements OnInit {
    @Input() invoice: Invoice;
    @Input() hasFinalInspect: boolean;
    @Input() hasInternalInspect: boolean;
    isInternal: boolean;
    showApprove: boolean;
    showDisapprove: boolean;
    constructor(private events: GlobalEvents) {}
    ngOnInit() {
        const { showApprove, showDisapprove } = showInvoiceInspectOptions(this.invoice);
        this.showApprove = showApprove;
        this.showDisapprove = showDisapprove;
        // console.log(
        //     `
        //     fullWo: ${this.invoice.fullWo}
        //     isApproved: ${this.invoice.isApproved}
        //     isInspected: ${this.invoice.isInspected}
        //     isInternalInspected: ${this.invoice.isInternalInspected}
        //     isInternal: ${this.isInternal}
        //     showApprove: ${this.showApprove}
        //     showDisapprove: ${this.showDisapprove}`
        // );
    }

    approveInspect() {
        this.events.publish({
            event: this.isInternal ? 'APPROVE_INSPECT' : 'APPROVE_FINAL_INSPECT',
            data: this.invoice,
        });
    }

    disapproveInspect() {
        this.events.publish({
            event: this.isInternal ? 'DISAPPROVE_INSPECT' : 'DISAPPROVE_FINAL_INSPECT',
            data: this.invoice,
        });
    }
}
