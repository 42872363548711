import { Component, OnInit } from '@angular/core';
import { ModalController, Platform, ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from '../../../../../srs-common/src/models';
import { InvoiceService } from 'src/app/providers/invoice-service/invoice.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PhotoHelper } from 'src/app/photo-helper';
import { Browser } from '@capacitor/browser';
@Component({
    selector: 'app-photo-modal',
    templateUrl: './photo-modal.page.html',
    styleUrls: ['./photo-modal.page.scss'],
})
export class PhotoModalPage implements OnInit {
    myImage: { file: string; filename: string; isImage?: boolean } = null;
    imageLoaded = false;
    invoice: Invoice;
    carId: number;
    uploadedImages: { name: string; url: string; isImage?: boolean }[] = [];
    delete = false;
    canEdit: boolean;
    photoHelper: PhotoHelper;
    isWoPhoto: boolean;
    tempId: string;
    title: string;
    constructor(
        public modalCtrl: ModalController,
        private translate: TranslateService,
        private actionSheetCtrl: ActionSheetController,
        private platform: Platform,
        private invoiceService: InvoiceService,
        private screenOrientation: ScreenOrientation
    ) {
        this.photoHelper = new PhotoHelper('image-button', this.screenOrientation, platform);
    }

    async ngOnInit() {
        if (!this.isWoPhoto) {
            this.uploadedImages = await this.invoiceService.getImages(this.invoice.id, this.carId);
        } else {
            if (this.invoice.id) {
                this.uploadedImages = await this.invoiceService.getWoImages(this.invoice.id, this.carId);
            } else {
                this.uploadedImages = await this.invoiceService.getTmpWoImages(this.carId, this.tempId);
            }
        }
    }

    toggleDelete() {
        this.delete = !this.delete;
    }

    clickFile() {
        this.presentActionSheet();
    }

    clear() {
        this.myImage = null;
        (document.getElementById('image-button') as HTMLInputElement).value = null;
    }

    async upload() {
        let newImg;
        if (!this.isWoPhoto) {
            newImg = await this.invoiceService.submitImage(this.invoice.id, this.carId, this.myImage);
        } else {
            if (this.invoice.id) {
                newImg = await this.invoiceService.submitWoImage(this.invoice.id, this.carId, this.myImage);
            } else {
                newImg = await this.invoiceService.submitTmpWoImage(this.carId, this.myImage, this.tempId);
            }
        }
        this.uploadedImages.push(newImg);
        this.myImage = null;
        (document.getElementById('image-button') as HTMLInputElement).value = null;
    }

    presentActionSheet() {
        this.translate.get(['TAKE_PHOTO', 'DOCUMENT', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                buttons: [
                    {
                        text: res.TAKE_PHOTO,
                        handler: () => {
                            this.photoHelper.takeAndroidPicture(40).then((img) => {
                                this.myImage = { file: img, filename: null, isImage: true };
                            });
                        },
                    },
                    {
                        text: res.DOCUMENT,
                        handler: () => {
                            document.getElementById('image-button').click();
                        },
                    },
                    {
                        text: res.CANCEL,
                        handler: () => {
                            this.modalCtrl.dismiss();
                        },
                    },
                ],
            });
            actionSheet.present();
        });
    }

    deleteImage(pos: number) {
        this.translate.get(['DELETE_PHOTO', 'ACCEPT', 'CANCEL']).subscribe(async (res) => {
            const actionSheet = await this.actionSheetCtrl.create({
                header: res.DELETE_PHOTO,
                buttons: [
                    {
                        text: res.ACCEPT,
                        handler: async () => {
                            if (this.isWoPhoto) {
                                if (this.invoice.id) {
                                    await this.invoiceService.deleteWoImage(
                                        this.invoice.id,
                                        this.carId,
                                        this.uploadedImages[pos].name
                                    );
                                } else {
                                    await this.invoiceService.deleteTmpWoImage(
                                        this.carId,
                                        this.uploadedImages[pos].name,
                                        this.tempId
                                    );
                                }
                            } else {
                                await this.invoiceService.deleteImage(
                                    this.invoice.id,
                                    this.carId,
                                    this.uploadedImages[pos].name
                                );
                            }
                            this.uploadedImages.splice(pos, 1);
                        },
                    },
                    {
                        text: res.CANCEL,
                        handler: () => {},
                    },
                ],
            });
            actionSheet.present();
        });
    }

    dismiss() {
        this.modalCtrl.dismiss({ images: this.uploadedImages.length });
    }

    async handleFileSelect(evt) {
        this.myImage = await this.photoHelper.handleFileSelect(evt);
    }

    async viewImage(pos: number) {
        const modal = await this.modalCtrl.create({
            component: ImageViewerComponent,
            componentProps: {
                imgSource: this.uploadedImages,
                pos,
            },
            keyboardClose: true,
            swipeToClose: false,
        });
        return await modal.present();
    }
    async viewFile(url: string) {
        Browser.open({ url });
    }
}
