import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CroppieModule } from '@gonzaller/angular-croppie-module';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, AppConfig } from './app.config';
import { InspectionModule } from './components/inspection/inspection.module';
import { PrintTypesPopoverComponent } from './components/print-types-popover/print-types-popover.component';
import { WoListPopoverComponent } from './components/wo-list-popover/wo-list-popover.component';
import { WoPopoverComponent } from './components/wo-popover/wo-popover.component';
import { OrderListPopoverComponent } from './fuel/components/wo-list-popover/wo-list-popover.component';
import { HttpService } from './interceptors/http.service';
import { EventDetailComponent } from './kta/modals/event-detail/event-detail.component';
import { QrDetailComponent } from './kta/modals/qr-detail/qr-detail.component';
import { KtaViewLocationComponent } from './kta/modals/view-location/view-location.component';
import { CarListComponent } from './modals/car-list/car-list.component';
import { EditServiceNoteComponent } from './modals/edit-service-note/edit-service-note.component';
import { EditServicePieceworkComponent } from './modals/edit-service-piecework/edit-service-piecework.component';
import { EditServicePriceComponent } from './modals/edit-service-price/edit-service-price.component';
import { EditServiceRopoComponent } from './modals/edit-service-ropo/edit-service-ropo.component';
import { EmailDetailComponent } from './modals/email-detail/email-detail.component';
import { EmailComponent } from './modals/email/email.component';
import { FilterSelectorComponent } from './modals/filter-selector/filter-selector.component';
import { GpsModuleComponent } from './modals/gps-module/gps-module.component';
import { ImageCropComponent } from './modals/image-cropper/image-cropper.component';
import { InspectionHistoryComponent } from './modals/inspection-history/inspection-history.component';
import { NewExpenseComponent } from './modals/new-expense/new-expense.component';
import { NotificationsPage } from './modals/notifications/notifications.page';
import { PhoneModalComponent } from './modals/phone-modal/phone-modal.component';
import { ProductionReportDetailComponent } from './modals/production-report-detail/production-report-detail.component';
import { SignatureComponent } from './modals/signature/signature.component';
import { ViewLocationComponent } from './modals/view-location/view-location.component';
import { DamagePage } from './pages/damage/damage.page';
import { ImageViewerComponent } from './pages/image-viewer/image-viewer.component';
import { PhotoModalPage } from './pages/photo-modal/photo-modal.page';
import { PipesModule } from './pipes/pipes.module';
import { AuthService } from './providers/auth-service/auth.service';
import { NotificationService } from './providers/notification-service/notification.service';
import { PhotoService } from './services/photo.service';
import { StorageService } from './services/storage.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        DamagePage,
        PhotoModalPage,
        FilterSelectorComponent,
        EmailDetailComponent,
        CarListComponent,
        EmailComponent,
        NotificationsPage,
        InspectionHistoryComponent,
        ImageViewerComponent,
        ImageCropComponent,
        WoListPopoverComponent,
        OrderListPopoverComponent,
        WoPopoverComponent,
        PrintTypesPopoverComponent,
        ViewLocationComponent,
        KtaViewLocationComponent,
        EditServicePriceComponent,
        EditServiceNoteComponent,
        EditServiceRopoComponent,
        EditServicePieceworkComponent,
        QrDetailComponent,
        EventDetailComponent,
        ProductionReportDetailComponent,
        SignatureComponent,
        PhoneModalComponent,
        GpsModuleComponent,
        NewExpenseComponent,
    ],
    entryComponents: [
        DamagePage,
        PhotoModalPage,
        FilterSelectorComponent,
        EmailDetailComponent,
        CarListComponent,
        EmailComponent,
        NotificationsPage,
        InspectionHistoryComponent,
        ImageViewerComponent,
        ImageCropComponent,
        WoListPopoverComponent,
        PrintTypesPopoverComponent,
        OrderListPopoverComponent,
        WoPopoverComponent,
        ViewLocationComponent,
        KtaViewLocationComponent,
        EditServicePriceComponent,
        EditServiceNoteComponent,
        EditServiceRopoComponent,
        QrDetailComponent,
        EventDetailComponent,
        ProductionReportDetailComponent,
        SignatureComponent,
        PhoneModalComponent,
        GpsModuleComponent,
        NewExpenseComponent,
    ],
    imports: [
        BrowserModule,
        PipesModule,
        InspectionModule,
        CroppieModule,
        IonicModule.forRoot(),
        HttpClientModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        FormsModule,
        GoogleMapsModule,
    ],
    providers: [
        BarcodeScanner,
        ScreenOrientation,
        AuthService,
        TranslateService,
        NotificationService,
        StorageService,
        PhotoService,
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpService,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
