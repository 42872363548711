import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Geolocation } from '@capacitor/geolocation';
@Injectable({
    providedIn: 'root',
})
export class GlobalEvents {
    private queue = new Subject<{ event: string; data: any }>();
    private geoWatcher: string;
    private interval;
    public lastCoords: { latitude: number; longitude: number } = { latitude: null, longitude: null };
    constructor() {
        //window['publish'] = (e) => this.publish(e);
    }
    publish(data: { event: string; data: any }) {
        this.queue.next(data);
        // console.log(data);
    }

    getObservable(): Subject<{ event: string; data: any }> {
        return this.queue;
    }

    watchLocation() {
        if (!this.geoWatcher) {
            Geolocation.watchPosition({ enableHighAccuracy: true }, (position) => {
                if (position) {
                    this.lastCoords = position.coords;
                    this.publish({
                        event: 'LOCATION_UPDATE',
                        data: position.coords,
                    });
                }
            }).catch(async (e) => {
                await Geolocation.requestPermissions();
                setTimeout(() => {
                    this.watchLocation();
                }, 500);
            });
        }
    }

    stopWatchLocation() {
        if (this.geoWatcher) {
            Geolocation.clearWatch({ id: this.geoWatcher });
            this.geoWatcher = '';
        }
    }

    setEventInterval(event: string, interval: number) {
        this.interval = setInterval(() => {
            this.publish({
                event,
                data: null,
            });
        }, interval);
    }
    clearEventInterval() {
        clearInterval(this.interval);
    }
}
