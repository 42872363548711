import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { GlobalEvents } from 'src/app/services/events.service';
import { StorageService } from 'src/app/services/storage.service';
import { User } from '../../../../../srs-common/src/models';
import { BaseService } from '../BaseService';
import { AuthService } from '../auth-service/auth.service';

export interface Expense {
    id?: number;
    amount: number;
    ticketDate: string;
    hasMainPhoto: string;
    hasOtherPhoto: boolean;
    idDealer: number;
    lat: number;
    lng: number;
    notes: string;
    isApproved: string;
    authorApproved: number;
    approvedDate: Date;
    cretedDate: Date;
    estado: number;
    category: { id: number; name: string };
    company: { id: number; name: string };
    author?: User;
}

@Injectable({
    providedIn: 'root',
})
export class ExpensesService extends BaseService {
    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        http: HttpClient,
        private storage: StorageService,
        private authService: AuthService,
        private translate: TranslateService,
        globalEvents: GlobalEvents,
        alertController: AlertController
    ) {
        super(http, globalEvents, alertController);
    }

    async uploadTicketImage(id: number, img: string) {
        const idDealer = this.authService.activeDealer.id;
        await this.apiPost(`${this.config.apiEndpoint}/php/api/modulos/expense/changePhoto/${idDealer}/${id}`, {
            img,
        });
    }

    async getCompanies() {
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/modulos/genericdata/fetch/34`);
        return resp.data.map((r) => this.parseGenericParameterFromResponse(r));
    }

    async getCategories() {
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/modulos/genericdata/fetch/35`);
        return resp.data.map((r) => this.parseGenericParameterFromResponse(r));
    }

    async updateStatus(id: number, isApproved: boolean) {
        await this.apiPut(`${this.config.apiEndpoint}/php/api/modulos/expense/edit`, {
            updateFields: 'isApproved',
            id,
            isApproved: isApproved ? 1 : 0,
        });
    }

    async deleteExpense(id: number) {
        await this.apiDelete(`${this.config.apiEndpoint}/php/api/modulos/expense/delete/${id}`);
    }

    async saveExpense(expense: {
        id?: number;
        company: { id?: number; name: string };
        category: { id: number; name: string };
        hasMainPhoto: number;
        hasOtherPhoto: number;
        ticketDate: string;
        ticketAmount: number;
        notes: string;
        author?: { id: number };
    }): Promise<number> {
        const { category, company, hasMainPhoto, hasOtherPhoto, notes, ticketAmount, ticketDate, author, id } = expense;
        const ids = await this.authService.getActiveIds();
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/modulos/expense/save`, {
            id,
            estado: id ? '1' : null,
            company,
            category,
            dealer: { id: ids.idDealer },
            detailer: { id: ids.idDealerProvider },
            amount: ticketAmount,
            ticketDate,
            hasMainPhoto,
            hasOtherPhoto,
            lat: this.events.lastCoords.latitude,
            lng: this.events.lastCoords.longitude,
            notes,
            author,
        });
        return resp.data.id;
    }

    async getExpensesPage(
        idAuthor: number,
        idDealer: number,
        isApproved: null | '1' | '2',
        dateFrom: string,
        dateTo: string,
        page: number,
        pageSize: number
    ): Promise<{ data: Expense[]; total: number }> {
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/modulos/expense/fetch`, {
            idAuthor,
            idDealer,
            isApproved,
            dateFrom,
            dateTo,
            page,
            pageSize,
        });
        return {
            data: resp.data.map((d) => {
                return this.parseExpenseFromResponse(d);
            }),
            total: resp.recordsTotal,
        };
    }

    parseGenericParameterFromResponse(r: any) {
        if (!r) {
            return null;
        }

        return {
            id: r.id,
            name: r.name,
        };
    }

    parseExpenseFromResponse(r: any): Expense {
        if (!r) {
            return null;
        }
        return {
            id: +r.id,
            amount: +r.amount,
            ticketDate: r.ticketDate,
            hasMainPhoto: r.hasMainPhoto,
            hasOtherPhoto: r.hasOtherPhoto,
            lat: r.lat,
            lng: r.lng,
            notes: r.notes,
            isApproved: r.isApproved,
            authorApproved: r.authorApproved,
            approvedDate: r.approvedDate,
            cretedDate: r.cretedDate,
            estado: r.estado,
            company: this.parseGenericParameterFromResponse(r.company),
            category: this.parseGenericParameterFromResponse(r.category),
            idDealer: +r.dealer.id,
            author: r.author ? ({ id: +r.author.id, name: r.author.nombre } as User) : null,
        };
    }

    async getExpenseImages(expense: Expense) {
        const resp = await this.apiGet(
            `${this.config.nodeEndpoint}/expenses/tickets/${expense.idDealer}/${expense.id}`
        );
        return resp.map((image) => ({
            url: `${this.config.nodeEndpoint}/expenses/tickets/${expense.idDealer}/${expense.id}/${image}`,
        }));
    }
}
