import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export let APP_CONFIG = new InjectionToken('app.config');

export interface IAppConfig {
    apiEndpoint: string;
    nodeEndpoint: string;
    isProd: boolean;
    appVersion: string;
    appName: string;
    pushAppId: string;
    pushGoogleId: string;
    playStore: string;
    appStore: string;
}

export const AppConfig: IAppConfig = {
    isProd: environment.production,
    apiEndpoint: environment.apiEndpoint,
    nodeEndpoint: environment.nodeEndpoint,
    appVersion: '1.1.1',
    appName: 'SRS',
    pushAppId: 'ec728f43-432c-4363-b448-3e3d291d38ad',
    pushGoogleId: '813322463622',
    playStore: 'https://play.google.com/store/apps/details?id=com.srs.suite',
    appStore: 'https://apps.apple.com/us/app/srs-suite/id1501364252',
};
