import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Platform } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
export class PhotoHelper {
    inputId: string;
    mimeType: string;
    filename: string;
    isImage: boolean;
    private isDom: boolean;
    constructor(inputId: string, private screenOrientation: ScreenOrientation, private platform: Platform) {
        this.inputId = inputId;
        platform.ready().then((value) => {
            this.isDom = value == 'dom';
        });
    }

    async takeAndroidPicture(quality: number) {
        this.screenOrientation.unlock();
        const photo = await Camera.getPhoto({
            quality,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl,
        });
        if (!this.isDom) {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
            this.clearPicturesFolder();
        }
        return photo.dataUrl;
    }

    async clearPicturesFolder() {
        if (this.platform.is('android')) {
            const picturesFolder = await Filesystem.getUri({ directory: Directory.External, path: 'Pictures' });
            Filesystem.readdir({ path: picturesFolder.uri }).then((dir) => {
                dir.files.forEach((file) => {
                    Filesystem.deleteFile({ path: picturesFolder.uri + '/' + file });
                });
            });
        }
    }

    handleFileSelect(evt) {
        return new Promise<{ file: string; filename: string; isImage?: boolean }>((resolve, reject) => {
            const files = evt.target.files;
            const file = files[0];
            if (files && file) {
                const reader = new FileReader();
                this.mimeType = file.type;
                this.filename = file.name;
                this.isImage = !!file.type.match(/[\/.](gif|jpg|jpeg|tiff|png)$/i);
                reader.readAsBinaryString(file);
                reader.onload = this._handleReaderLoaded.bind(this, resolve);
            }
        });
    }

    _handleReaderLoaded(resolve, readerEvt) {
        const binaryString = readerEvt.target.result;
        resolve({
            file: 'data:' + this.mimeType + ';base64,' + btoa(binaryString),
            filename: this.filename,
            isImage: this.isImage,
        });
    }
}
