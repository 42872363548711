import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { QRCodeCarServiceEvent } from '../../../../../../srs-common/src/models';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
    event: QRCodeCarServiceEvent;
    photo: string;
    mapOptions: google.maps.MapOptions = {
        disableDefaultUI: true,
        mapTypeId: 'hybrid',
        tilt: 0,
        styles: [
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
        ],
    };
    coords: { lat: number; lng: number } = null;
    constructor(@Inject(APP_CONFIG) private config: IAppConfig, private modalCtrl: ModalController) {}

    ngOnInit() {
        this.coords = this.event.lat ? { lat: this.event.lat, lng: this.event.lng } : null;
        if (this.event.photoName) {
            this.photo = `${this.config.nodeEndpoint}/key_tracker/service/img/${this.event.photoName}`;
        }
    }

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
