import { Component, Input, OnInit } from '@angular/core';
import { Invoice } from '../../../../../../srs-common/src/models';

@Component({
    selector: 'inspection-flag',
    templateUrl: './inspection-flag.component.html',
    styleUrls: ['./inspection-flag.component.scss'],
})
export class InspectionFlagComponent implements OnInit {
    @Input() invoice: Invoice;
    @Input() type: number;
    @Input() status: number;
    constructor() {}

    ngOnInit() {
        // console.log(this.invoice.fullWo);
        // console.log(this.invoice.isApproved);
        // console.log(this.invoice.isInternalInspected);
    }
}
