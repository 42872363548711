import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QRCode } from '../../../../../../srs-common/src/models';

@Component({
    selector: 'app-qr-detail',
    templateUrl: './qr-detail.component.html',
    styleUrls: ['./qr-detail.component.scss'],
})
export class QrDetailComponent implements OnInit {
    qrData: QRCode;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    dismiss(data?: any) {
        this.modalCtrl.dismiss(data);
    }
}
