import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Geolocation } from '@capacitor/geolocation';
import { AlertController } from '@ionic/angular';
import { APP_CONFIG, IAppConfig } from 'src/app/app.config';
import { GlobalEvents } from 'src/app/services/events.service';
import {
    InspectionHistory,
    Invoice,
    Phone,
    getEmailFromResponse,
    getInspectionHistoryFromResponse,
    getInvoiceFromResponse,
    invoiceToRequest,
    toStringDate,
} from '../../../../../srs-common/src/models';
import { BaseService } from '../BaseService';
import { AuthService } from '../auth-service/auth.service';
@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends BaseService {
    lastInvoiceParams: any;
    imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;

    constructor(
        @Inject(APP_CONFIG) private config: IAppConfig,
        private authService: AuthService,
        http: HttpClient,
        events: GlobalEvents,
        alertCtrl: AlertController
    ) {
        super(http, events, alertCtrl);
    }

    async getInvoice(id: number) {
        const user = await this.authService.getUser();
        const resp = await this.apiGet(`${this.config.apiEndpoint}/php/api/invoice.php?id_usuario=${user.id}&id=` + id);
        return getInvoiceFromResponse(resp.data as any);
    }

    async getInvoiceInspectionHistory(id: number): Promise<InspectionHistory[]> {
        const user = await this.authService.getUser();
        const resp = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/modulos/workorders/inspectedHistory/${id}?id_usuario=${user.id}`
        );
        return resp.data.map((i) => getInspectionHistoryFromResponse(i));
    }

    async deleteWO(idsWo: string) {
        const resp = await this.apiDelete(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            body: {
                idsWo,
            },
        });
        return resp;
    }

    async getInvoiceByFilterNextPage() {
        this.lastInvoiceParams.page = this.lastInvoiceParams.page + 1;
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, this.lastInvoiceParams);
        return resp.data.map((i) => getInvoiceFromResponse(i as any));
    }

    async getInvoiceByFilter(idWorkflowIn: string, providerId: string, filters?: any) {
        const ids = await this.authService.getActiveIds();
        const user = await this.authService.getUser();
        this.lastInvoiceParams = {
            idDealerProvider: providerId ? providerId : null,
            idDealer: ids.idDealer,
            idUserLogged: !user.isDetailer ? ids.idUsuario : null,
            idWorkflowIn,
            showScanner: 1,
            orderBy: 'i.id_workflow, i.fecha_alta DESC',
            allowUserApprove: ids.idUsuario,
            limit: 50,
            page: 0,
            ...filters,
        };
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, this.lastInvoiceParams);
        return resp.data.map((i) => getInvoiceFromResponse(i as any));
    }

    async getInvoiceByServiceFilter(idWorkflowIn: string, providerId: string, idInvServiceExistsIn: string) {
        const ids = await this.authService.getActiveIds();
        const user = await this.authService.getUser();
        this.lastInvoiceParams = {
            idDealerProvider: providerId ? providerId : null,
            idDealer: ids.idDealer,
            idUserLogged: !user.isDetailer ? ids.idUsuario : null,
            idWorkflowIn,
            showScanner: 1,
            orderBy: 'i.id_workflow, i.fecha_alta DESC',
            allowUserApprove: ids.idUsuario,
            idInvServiceExistsIn,
            limit: 50,
            page: 0,
        };
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, this.lastInvoiceParams);
        return resp.data.map((i) => getInvoiceFromResponse(i as any));
    }

    async validateInvoice(invoice: Invoice, idCar: number) {
        const resp = await this.apiPost(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            idInvServiceExistsIn: invoice.invoiceServices.map((s) => s.id).join(','),
            fechaAltaDesde: toStringDate(invoice.entryDate, true),
            fechaAltaHasta: toStringDate(invoice.entryDate, true),
            idCar,
            idWorkorder: invoice.id ? invoice.id : null,
        });
        return resp.data.length;
    }

    async sendInvoice(invoice: Invoice, userId: number, carId: number, dealerId: number) {
        let coords;
        const dealer = await this.authService.getActiveDealer();
        if (dealer.hasGeoWo) {
            const permissions = await Geolocation.checkPermissions();
            if (permissions.location === 'granted') {
                const pos = await Geolocation.getCurrentPosition({ enableHighAccuracy: true }).catch(() => ({
                    coords: { latitude: null, longitude: null },
                }));
                coords = pos.coords;
            }
        }
        const activeIds = await this.authService.getActiveIds();
        const invoiceRequest = invoiceToRequest(invoice, userId, carId, dealerId);
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/invoice.php`, {
            ...invoiceRequest,
            lat: coords?.latitude,
            lng: coords?.longitude,
            idUsuario: activeIds.idUsuario,
        });
        return getInvoiceFromResponse(resp.data as any);
    }

    async sendInvoiceEmail(idsInvoice: string, emailto: string, type: string) {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/sendmail.php`, {
            tipo: 'sendmail',
            idsInvoice,
            emailto,
            idDealer,
            idAuthor: idUsuario,
            type,
        });
    }

    async getSentEmails(fromDate: string, toDate: string) {
        const { idUsuario } = await this.authService.getActiveIds();
        const { data } = await this.apiPost(`${this.config.apiEndpoint}/php/api/api.emails.php`, {
            fechaDesde: fromDate,
            fechaHasta: toDate,
            idAuthor: idUsuario,
        });
        return data.map((d) => getEmailFromResponse(d));
    }

    async getRecentEmails(): Promise<string[]> {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/getEmails.php?id_author=${idUsuario}&id_dealer=${idDealer}`
        );
        return data.map((e) => e.email);
    }
    async deleteRecentEmail(email: string): Promise<void> {
        const { idDealer, idUsuario } = await this.authService.getActiveIds();
        return await this.apiDelete(
            `${this.config.apiEndpoint}/php/api/getEmails.php?id_author=${idUsuario}&email=${email}&hide_loading`
        );
    }

    async printWO(idsInvoice: string, type: string) {
        const url = `${this.config.apiEndpoint}/modulos/invoices/php/invoice_pdf.php?ids_invoices=${idsInvoice}&type=${type}&token=${AuthService.token}`;
        await Browser.open({ url });
    }

    async approveInvoice(approve: boolean, idInvoice: number, note?: string) {
        const activeIds = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/api.invoiceActions.php`, {
            idInvoice,
            approve: approve ? '1' : '0',
            tipo: 'approved',
            idUsuario: activeIds.idUsuario,
            note,
        });
    }

    async approveInspect(
        approve: boolean,
        idInvoice: number,
        note?: string,
        signature?: string,
        contactDealer?: Phone,
        isInternal?: boolean,
        idInvoices?: string
    ) {
        const { lastCoords } = this.events;
        let lat, lng;
        if (lastCoords && lastCoords.latitude) {
            lat = lastCoords.latitude;
            lng = lastCoords.longitude;
        }
        const activeIds = await this.authService.getActiveIds();
        return this.apiPost(`${this.config.apiEndpoint}/php/api/api.invoiceActions.php`, {
            idInvoice,
            inspect: approve ? '1' : '0',
            tipo: isInternal ? 'intinspect' : 'inspect',
            idUsuario: activeIds.idUsuario,
            note,
            lat,
            lng,
            signature,
            contactDealer,
            idInvoices,
        });
    }

    async getImages(invoiceId: number, carId: number) {
        const imgList: string[] = await this.apiGet(
            `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}?env=${this.config.isProd ? 'prod' : 'test'}`
        );
        const resp: { name: string; url: string; isImage?: boolean }[] = [];
        imgList.forEach((i) => {
            resp.push({
                url: `${this.config.nodeEndpoint}/invoices${
                    this.config.isProd ? '' : '_test'
                }/img/${carId}/${invoiceId}/${i}`,
                name: i,
                isImage: !!i.match(this.imageReg),
            });
        });
        return resp;
    }

    async getWoImages(invoiceId: number, carId: number) {
        const imgList: string[] = await this.apiGet(
            `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}?env=${this.config.isProd ? 'prod' : 'test'}`
        );
        const resp: { name: string; url: string; isImage?: boolean }[] = [];
        imgList.forEach((i) => {
            resp.push({
                url: `${this.config.nodeEndpoint}/wo${
                    this.config.isProd ? '' : '_test'
                }/img/${carId}/${invoiceId}/${i}`,
                name: i,
                isImage: !!i.match(this.imageReg),
            });
        });
        return resp;
    }

    async getTmpWoImages(carId: number, tempId: string) {
        const imgList: string[] = await this.apiGet(
            `${this.config.nodeEndpoint}/${carId}/wo/${tempId}/tmp_img?env=${this.config.isProd ? 'prod' : 'test'}`
        );
        const resp: { name: string; url: string; isImage?: boolean }[] = [];
        imgList.forEach((i) => {
            resp.push({
                url: `${this.config.nodeEndpoint}/wo${
                    this.config.isProd ? '' : '_test'
                }/tmp_img/${carId}/${tempId}/${i}`,
                name: i,
                isImage: !!i.match(this.imageReg),
            });
        });
        return resp;
    }

    async deleteImage(invoiceId: number, carId: number, filename: string) {
        return this.apiDelete(
            `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}/${filename}?env=${
                this.config.isProd ? 'prod' : 'test'
            }`
        );
    }

    async deleteWoImage(invoiceId: number, carId: number, filename: string) {
        return this.apiDelete(
            `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}/${filename}?env=${
                this.config.isProd ? 'prod' : 'test'
            }`
        );
    }

    async deleteTmpWoImage(carId: number, filename: string, tempId: string) {
        return this.apiDelete(
            `${this.config.nodeEndpoint}/${carId}/wo/${tempId}/tmp/${filename}?env=${
                this.config.isProd ? 'prod' : 'test'
            }`
        );
    }

    async moveTmpWoImages(invoiceId: number, carId: number, tempId: string) {
        return this.apiPost(
            `${this.config.nodeEndpoint}/${carId}/wo/${tempId}/${invoiceId}/tmp_img_move?env=${
                this.config.isProd ? 'prod' : 'test'
            }`,
            {}
        );
    }

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    submitImage(invoiceId: number, carId: number, image: { file: string; filename: string }) {
        const blob = this.dataURItoBlob(image.file);
        const fd = new FormData(document.forms[0]);
        if (image.filename) {
            fd.append('file', blob, image.filename);
        } else {
            fd.append('file', blob);
        }
        return new Promise<{ name: string; url: string; isImage: boolean }>(async (resolve, reject) => {
            this.http
                .post(
                    `${this.config.nodeEndpoint}/${carId}/invoice/${invoiceId}/img_upload?env=${
                        this.config.isProd ? 'prod' : 'test'
                    }`,
                    fd,
                    {
                        reportProgress: true,
                        observe: 'events',
                    }
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve({
                                url: `${this.config.nodeEndpoint}/invoices${
                                    this.config.isProd ? '' : '_test'
                                }/img/${carId}/${invoiceId}/${event.body.fileUrl}`,
                                name: event.body.fileUrl,
                                isImage: !!(event.body.fileUrl as string).match(this.imageReg),
                            });
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    submitWoImage(invoiceId: number, carId: number, image: { file: string; filename: string }) {
        const blob = this.dataURItoBlob(image.file);
        const fd = new FormData(document.forms[0]);
        if (image.filename) {
            fd.append('file', blob, image.filename);
        } else {
            fd.append('file', blob);
        }
        return new Promise<{ name: string; url: string; isImage: boolean }>(async (resolve, reject) => {
            this.http
                .post(
                    `${this.config.nodeEndpoint}/${carId}/wo/${invoiceId}/img_upload?env=${
                        this.config.isProd ? 'prod' : 'test'
                    }`,
                    fd,
                    {
                        reportProgress: true,
                        observe: 'events',
                    }
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'wo_image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve({
                                url: `${this.config.nodeEndpoint}/wo${
                                    this.config.isProd ? '' : '_test'
                                }/img/${carId}/${invoiceId}/${event.body.fileUrl}`,
                                name: event.body.fileUrl,
                                isImage: !!(event.body.fileUrl as string).match(this.imageReg),
                            });
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    submitTmpWoImage(carId: number, image: { file: string; filename: string }, tempId: string) {
        const blob = this.dataURItoBlob(image.file);
        const fd = new FormData(document.forms[0]);
        if (image.filename) {
            fd.append('file', blob, image.filename);
        } else {
            fd.append('file', blob);
        }
        return new Promise<{ name: string; url: string; isImage: boolean }>(async (resolve, reject) => {
            this.http
                .post(
                    `${this.config.nodeEndpoint}/${carId}/wo/${tempId}/tmp_img_upload?env=${
                        this.config.isProd ? 'prod' : 'test'
                    }`,
                    fd,
                    {
                        reportProgress: true,
                        observe: 'events',
                    }
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round((100 * event.loaded) / event.total);
                            this.events.publish({ event: 'wo_image_upload_percentage', data: percentDone });
                        } else if (event instanceof HttpResponse) {
                            resolve({
                                url: `${this.config.nodeEndpoint}/wo${
                                    this.config.isProd ? '' : '_test'
                                }/tmp_img/${carId}/${tempId}/${event.body.fileUrl}`,
                                name: event.body.fileUrl,
                                isImage: !!(event.body.fileUrl as string).match(this.imageReg),
                            });
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async updateFields(data: {
        woId: number;
        workflow: { id: number; comment?: string };
        employees?: number[];
        updateFields: string;
    }) {
        const { woId, workflow, employees, updateFields } = data;
        const activeIds = await this.authService.getActiveIds();
        const resp = await this.apiPut(`${this.config.apiEndpoint}/php/api/invoiceUpdateFields.php`, {
            author: { id: activeIds.idUsuario },
            id: woId,
            workflow,
            employees,
            updateFields,
        });
        return resp.data;
    }

    getDownloadUrl(file, emailId) {
        return `${this.config.apiEndpoint}/php/download.php?id_queue=${emailId}&id_file=${file.id}&file=${file.name}`;
    }

    async getPhones(query: string, typeContact?: number): Promise<Phone[]> {
        const activeIds = await this.authService.getActiveIds();
        const { data } = await this.apiGet(
            `${this.config.apiEndpoint}/php/api/api.phones.php?idDealer=${activeIds.idDealer}&phoneLike=${query}${
                typeContact ? '&typeContact=' + typeContact : ''
            }`
        );
        return data as Phone[];
    }
}
