import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
    providedIn: 'root',
})
export class PhotoService {
    private isDom: boolean;
    constructor(
        private screenOrientation: ScreenOrientation,
        private platform: Platform,
        private translate: TranslateService,
        private actionSheetCtrl: ActionSheetController
    ) {
        platform.ready().then((value) => {
            this.isDom = value == 'dom';
        });
    }

    async presentActionSheet() {
        return new Promise<string | null>((resolve, reject) => {
            this.translate.get(['TAKE_PHOTO', 'DOCUMENT', 'CANCEL']).subscribe(async (res) => {
                const actionSheet = await this.actionSheetCtrl.create({
                    buttons: [
                        {
                            text: res.TAKE_PHOTO,
                            handler: () => {
                                this.takePicture(40).then((img) => {
                                    resolve(img);
                                });
                            },
                        },
                        {
                            text: res.DOCUMENT,
                            handler: () => {
                                document.getElementById('image-button').click();
                                resolve(null);
                            },
                        },
                        {
                            text: res.CANCEL,
                            handler: () => {
                                resolve(null);
                            },
                        },
                    ],
                });
                actionSheet.present();
            });
        });
    }

    async takePicture(quality: number) {
        try {
            this.screenOrientation.unlock();
            const photo = await Camera.getPhoto({
                quality,
                allowEditing: false,
                source: CameraSource.Camera,
                resultType: CameraResultType.DataUrl,
                direction: CameraDirection.Front,
            });
            if (!this.isDom) {
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
                this.clearPicturesFolder();
            }
            return photo.dataUrl;
        } catch (error) {
            return null;
        }
    }

    async clearPicturesFolder() {
        if (this.platform.is('android')) {
            const picturesFolder = await Filesystem.getUri({ directory: Directory.External, path: 'Pictures' });
            Filesystem.readdir({ path: picturesFolder.uri }).then((dir) => {
                dir.files.forEach((file) => {
                    Filesystem.deleteFile({ path: picturesFolder.uri + '/' + file });
                });
            });
        }
    }

    handleFileSelect(evt) {
        return new Promise<string>((resolve, reject) => {
            const files = evt.target.files;
            const file = files[0];
            if (files && file) {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = this._handleReaderLoaded.bind(this, resolve);
            }
        });
    }

    _handleReaderLoaded(resolve, readerEvt) {
        const binaryString = readerEvt.target.result;
        resolve('data:image/jpeg;base64,' + btoa(binaryString));
    }
}
