import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InspectionFlagComponent } from './inspection-flag/inspection-flag.component';
import { InspectionSlideItemsComponent } from './inspection-slide-items/inspection-slide-items.component';

@NgModule({
    declarations: [InspectionFlagComponent, InspectionSlideItemsComponent],
    imports: [FormsModule, IonicModule, CommonModule, TranslateModule],
    exports: [InspectionFlagComponent, InspectionSlideItemsComponent],
})
export class InspectionModule {}
